/* eslint-disable max-lines */
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../../constants";
import {
  GreyBlock,
  GreyBlockTitle,
  IconAttention,
} from "../../../../../lib/GreyBlock";
import Link from "../../../../../lib/Link";
import { formatCurrency } from "../../../../../utils/currency";
import mediaQuery from "../../../../../utils/mediaQuery";
import { Authorization } from "../../../../models/Authorization";
import { UserPlanComplete } from "../../../../models/UserPlan";
import { BoldBlack, BrownGrey, Hr } from "../../style";
import CostSharing from "./CostSharing";
import { IndividualCost } from "./IndividualCost";
import { Title, Value, GridMobile } from "./Responsibility.style";

export type Props = {
  authorization: Authorization;
  userPlan: UserPlanComplete;
};

const RedResponsibilityRow = styled.div`
  color: ${colors.coralBlueGreen};
  font-weight: 500;
  margin-bottom: 10px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 20px;
    `,
  )}
`;

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  line-height: 22px;
  margin-bottom: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-template-columns: 33% 34% 33%;
      margin-bottom: 30px;
    `,
  )}
`;

const MainGridItem = styled.div`
  border-bottom: 1px solid ${colors.brownLightGrey1};
  padding: 20px 0 20px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  ${mediaQuery(
    "desktopMedium",
    css`
      border-bottom: none;
      border-left: 1px solid ${colors.brownLightGrey1};
      padding: 0 10px 0 10px;
      &:first-child {
        border-left: none;
        padding: 0;
      }
    `,
  )}
`;

const StyledGridMobile = styled(GridMobile)`
  ${mediaQuery(
    "desktopMedium",
    css`
      grid-row-gap: 30px;
    `,
  )}
`;

const StyledHr = styled(Hr)`
  margin: 20px 0;
`;

const CellTitle = styled.div`
  color: ${colors.brownBlack};
  font-weight: 500;
  margin-bottom: 10px;

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 8px;
    `,
  )}
`;

const Small = styled.span`
  font-size: 12px;
`;

export const ResponsibilityBlockComplete: FC<Props> = ({
  authorization,
  userPlan,
}: Props) => {
  const isRx = authorization.practiceTemplate === "pharmacy";
  const i18n = useLingui();
  const {
    companyPlanType,
    companyPlanName,
    deductible,
    oopMax,
    noDeductibleRx,
  } = userPlan;

  return (
    <>
      <RedResponsibilityRow>
        <Trans>
          Your medical plan includes cost-sharing with individual
          responsibility:
        </Trans>
      </RedResponsibilityRow>
      <GreyBlock>
        {!!(companyPlanType && companyPlanName) && (
          <>
            <Title>
              <Trans>If your current plan type is</Trans>
            </Title>

            <BoldBlack>
              {companyPlanName}, {i18n._(companyPlanType.label)}
            </BoldBlack>
          </>
        )}

        <div>
          <Small>
            <Link to="/profile/completion/new">
              <Trans>Your plan has changed?</Trans>
            </Link>
          </Small>
        </div>

        <StyledHr />

        <Title>
          <Trans>Then your responsibility includes</Trans>
        </Title>

        <MainGrid>
          <MainGridItem>
            <StyledGridMobile>
              <div>
                <CellTitle>
                  <Trans>Total Deductible</Trans>
                </CellTitle>
                {typeof deductible === "number" ? (
                  <Value>
                    {formatCurrency(
                      deductible,
                      "USD",
                      i18n.i18n.locale,
                      {
                        maximumFractionDigits: 0,
                      },
                    )}
                  </Value>
                ) : (
                  <BrownGrey>
                    <Trans>cannot be shown</Trans>
                  </BrownGrey>
                )}
              </div>
              <div>
                <CellTitle>
                  <Trans>Out-of-pocket Max</Trans>
                </CellTitle>
                <Value>
                  {formatCurrency(oopMax, "USD", i18n.i18n.locale, {
                    maximumFractionDigits: 0,
                  })}
                </Value>
              </div>
            </StyledGridMobile>
          </MainGridItem>

          <MainGridItem>
            <CostSharing isRx={isRx} userPlan={userPlan} />
          </MainGridItem>

          <MainGridItem>
            <IndividualCost
              authorization={authorization}
              isRx={isRx}
              userPlan={userPlan}
            />
          </MainGridItem>
        </MainGrid>

        {isRx && !noDeductibleRx && (
          <>
            <StyledHr />
            <GreyBlockTitle
              icon={<IconAttention />}
              title={t`Important: The pharmacy should only collect your copay, not your deductible.`}
            />
            <p>
              <Trans>
                Stork Club will send you a bill for you applicable
                deductible after you receive your Rx.
              </Trans>
            </p>
            <p>
              <Trans>
                If the pharmacy asks you to pay your deductible
                directly, please contact your{" "}
                <Link to="/care-navigator">Care&nbsp;Navigator</Link>{" "}
                so we can help.
              </Trans>
            </p>
          </>
        )}

        {!isRx && (
          <>
            <StyledHr />
            <GreyBlockTitle
              icon={<IconAttention />}
              title={t`Important: You should not pay to our providers directly!`}
            />
            <p>
              <Trans>
                Instead, Stork Club will send you a bill with exact
                amount after you receive covered services so you could
                pay online via secure connection.
              </Trans>
            </p>
            <p>
              <Trans>
                If a Stork Club provider asks you to pay them directly
                contact your{" "}
                <Link to="/care-navigator">Care Navigator</Link>{" "}
                so&nbsp;we&nbsp;can&nbsp;help.
              </Trans>
            </p>
          </>
        )}
      </GreyBlock>
    </>
  );
};
