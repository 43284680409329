import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../constants";
import Alert from "../../../lib/Alert";
import Button from "../../../lib/Button";
import {
  LabelText,
  Label as FormFieldLabel,
} from "../../../lib/forms/FormField";
import { Label as RadioLabel } from "../../../lib/forms/primitives/Radio";
import mediaQuery from "../../../utils/mediaQuery";

export const StyledLink = styled(Link)`
  color: ${colors.blue};
  display: block;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: inline;
    `,
  )};
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 40px;
`;

export const AlertUl = styled.ul`
  margin: 0;
  padding-left: 20px;
`;

export const AlertLi = styled.li``;

export const NewLine = styled.br`
  display: none;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: block;
    `,
  )}
`;

export const StyledRadioField = styled.div`
  ${FormFieldLabel} {
    row-gap: 16px;
    flex-wrap: wrap;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        flex-wrap: nowrap;
      `,
    )};
  }

  ${LabelText} {
    margin-bottom: 14px;
    width: 100%;

    ${mediaQuery(
      "greaterThanPhone",
      css`
        flex-shrink: 0;
        margin-bottom: 0;
        width: 200px;
      `,
    )};
  }

  ${RadioLabel} {
    margin-inline-end: 40px;

    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-inline-end: 50px;
      `,
    )};
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Grid2x = styled.div`
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      grid-template-columns: 1fr 1fr;
    `,
  )};
`;

export const DobSpacer = styled.div`
  column-gap: 16px;
  display: grid;
  grid-template:
    "month month" auto
    "day year" auto
    / 1fr 1fr;
  row-gap: 12px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      column-gap: 30px;
      grid-template: "month day year rest" auto / auto auto auto 1fr;
    `,
  )}
`;

export const IsReadContainer = styled.span<{
  $hasError: boolean;
}>`
  align-items: start;
  background: ${colors.lightGreyBlue};
  border: 2px solid ${colors.lightGreyBlue};
  border-radius: 8px;
  box-sizing: border-box;
  color: #4c4c4c;
  display: flex;
  font-family: ${fonts.faktum};
  font-size: 16px;
  line-height: 20px;
  padding-block-end: 10px;
  padding-block-start: 11px;
  padding-inline-end: 10px;
  padding-inline-start: 13px;

  ${({ $hasError }) =>
    $hasError
      ? css`
          border-color: ${colors.red};
        `
      : undefined}

  a {
    color: #4c4c4c;
    text-decoration: underline;
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      max-width: 570px;
    `,
  )};
`;

export const StyledButton = styled(Button)`
  font-family: ${fonts.faktum};
  font-size: 20px;
  font-weight: 500;
  margin-top: 40px;
  padding-inline-end: 98px;
  padding-inline-start: 98px;

  ${mediaQuery(
    "phone",
    css`
      width: 100%;
    `,
  )};
`;

export const Description = styled.p`
  color: #4c4c4c;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 40px;
  max-width: 570px;
`;
