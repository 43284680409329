import { Trans } from "@lingui/macro";
import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import CookieYesButton from "../../../components/CookieYesButton";
import { fonts, colors } from "../../../constants";
import { LabelText } from "../../../lib/forms/FormField";
import {
  Input as RadioInput,
  Label as RadioLabel,
  Circle,
} from "../../../lib/forms/primitives/Radio";
import { Logo } from "../../../lib/Logo";
import { Text } from "../../../lib/Text";
import mediaQuery from "../../../utils/mediaQuery";

export interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  imgSrc: string;
}

export const networkifyMixin = css`
  font-family: ${fonts.faktum};
  ${Text}, ${LabelText}, ${RadioLabel} {
    font-family: ${fonts.faktum};
  }

  input {
    color: #4c4c4c;
    font-family: ${fonts.faktum};
    font-size: 20px;
    font-weight: 500;
    &::placeholder {
      color: #c4c4c4;
      font-family: ${fonts.faktum};
      font-size: 20px;
      font-weight: 500;
    }
  }

  ${LabelText} {
    color: #4c4c4c;
    font-size: 20px;
    line-height: 24px;
  }

  ${Circle} {
    color: #e5e5e5;
  }

  ${RadioLabel} {
    color: #4c4c4c;
    font-size: 20px;
    line-height: 24px;
  }

  ${RadioInput}:checked+${Circle} {
    color: ${colors.blueGreen};
  }
`;

const Container = styled.div`
  background-color: white;
  display: grid;
  grid-template-areas: "content";
  grid-template-columns: 1fr;
  min-height: 100%;

  ${networkifyMixin}

  ${mediaQuery(
    "greaterThanTablet",
    css`
      grid-template-areas: "content backgroundImage";
      grid-template-columns: 1fr 1fr;
    `,
  )}
`;

const LogoContainer = styled.div`
  grid-area: logo;
  margin-bottom: 40px;
  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 80px;
    `,
  )}

  div {
    background-size: 180px 27px;
    height: 27px;
    width: 180px;
    ${mediaQuery(
      "desktopMedium",
      css`
        background-size: 207px 31px;
        height: 31px;
        width: 207px;
      `,
    )}
  }
`;

const ContentContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-areas:
    "logo"
    "children";
  grid-template-rows: min-content 1fr;
  min-height: 100vh;
  padding: 24px 16px 20px;

  ${mediaQuery(
    "tablet",
    css`
      padding: 80px 120px;
    `,
  )}

  ${mediaQuery(
    "greaterThanTablet",
    css`
      padding: 40px;
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-block-end: 80px;
      padding-block-start: 80px;
      padding-inline-end: 40px;
      padding-inline-start: 120px;
    `,
  )}
`;
const ChildrenContainer = styled.div`
  grid-area: children;
`;
const BackgroundImage = styled.div<{ $src: string }>`
  background-image: url(${({ $src }) => $src});
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  grid-area: backgroundImage;
  ${mediaQuery(
    "desktopMedium",
    css`
      display: block;
    `,
  )}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Legal = styled.div`
  color: ${colors.brownGrey};
  font-size: 13px;
  line-height: 1.67;
  margin-top: 40px;

  /* Styled CookieYes button */
  button {
    color: ${colors.brownGrey};
    display: inline-flex;
    margin-inline-end: 10px;
    text-decoration: underline;
  }

  span {
    display: block;

    ${mediaQuery(
      "greaterThanPhone",
      css`
        display: none;
      `,
    )}
  }

  br {
    display: none;

    ${mediaQuery(
      "greaterThanPhone",
      css`
        display: block;
      `,
    )}
  }
`;

const StyledLink = styled.a`
  color: ${colors.brownGrey};
  margin-inline-end: 10px;
  text-decoration: underline;
`;

const Layout: React.FC<LayoutProps> = (props) => {
  const { children, imgSrc, ...restProps } = props;

  return (
    <Container {...restProps}>
      <ContentContainer>
        <Top>
          <LogoContainer>
            <Logo fill="#2d4f61" height={31} width={207} />
          </LogoContainer>
        </Top>
        <ChildrenContainer>{children}</ChildrenContainer>
        <Legal>
          <StyledLink
            href="https://joinstorkclub.com/legal/privacy_global"
            rel="noreferrer"
            target="_blank"
          >
            <Trans>Global Privacy Notice</Trans>
          </StyledLink>
          <StyledLink
            href="https://joinstorkclub.com/legal/privacy"
            rel="noreferrer"
            target="_blank"
          >
            <Trans>US Privacy Policy</Trans>
          </StyledLink>
          <span />
          <StyledLink
            href="https://joinstorkclub.com/legal/tos"
            rel="noreferrer"
            target="_blank"
          >
            <Trans>Terms of Service</Trans>
          </StyledLink>
          <br />
          <StyledLink
            href="https://joinstorkclub.com/legal/hipaa"
            rel="noreferrer"
            target="_blank"
          >
            <Trans>HIPAA Notice and Consent</Trans>
          </StyledLink>
          <CookieYesButton />
        </Legal>
      </ContentContainer>
      <BackgroundImage $src={imgSrc} />
    </Container>
  );
};

export default Layout;
