import { FC } from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../constants";
import mediaQuery from "../../utils/mediaQuery";

export type Props = {
  title: string;
  icon?: React.ReactNode;
};

export const GreyBlock = styled.div<{ overhangs?: string }>`
  background: #fafafa;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 0 -10px 0 -10px;
  padding: 15px 10px;
  position: relative;

  p {
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }

  ${({ overhangs = 20 }) =>
    mediaQuery(
      "desktopMedium",
      css`
        margin: 0 -${overhangs}px 0 -${overhangs}px;
        padding: 23px ${overhangs}px;
      `,
    )}
`;

const Icon = styled.div<{ bg?: string }>`
  align-items: center;
  background: ${({ bg = colors.coral }) => bg};
  border-radius: 50%;
  color: ${colors.black};
  display: inline-flex;
  flex-shrink: 0;
  height: 22px;
  justify-content: center;
  margin-inline-end: 7px;
  vertical-align: text-bottom;
  width: 22px;
`;

const CircleText = styled.span`
  font-size: 12px;
`;

const TitleText = styled.span`
  color: ${colors.brownBlack};
  font-family: ${fonts.alt};
  font-size: 19px;
  font-weight: 800;
  line-height: 24px;
  vertical-align: text-bottom;
`;

const GreyBlockTitleContainer = styled.div`
  margin: 0 0 20px;
  max-width: 522px;
`;

export const IconAttention = () => (
  <Icon bg={colors.coralStable}>
    <svg
      fill="none"
      height="10"
      viewBox="0 0 3 10"
      width="3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M0.327262 0H1.9677L1.84004 6.17239H0.454922L0.327262 0ZM1.14429 9.33838C0.627264 9.33838 0.21875 8.93625 0.21875 8.43199C0.21875 7.92773 0.627264 7.53198 1.14429 7.53198C1.6677 7.53198 2.07621 7.92773 2.07621 8.43199C2.07621 8.93625 1.6677 9.33838 1.14429 9.33838Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
);

export const IconInfo = () => (
  <Icon bg={colors.gold}>
    <svg
      fill="none"
      height="10"
      viewBox="0 0 2 10"
      width="2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.67221 9.21875H0.316634V3.10396H1.67221V9.21875ZM0.232956 1.51593C0.232956 1.30494 0.298039 1.12975 0.428204 0.990351C0.562088 0.85095 0.751758 0.78125 0.997212 0.78125C1.24267 0.78125 1.43234 0.85095 1.56622 0.990351C1.7001 1.12975 1.76705 1.30494 1.76705 1.51593C1.76705 1.72315 1.7001 1.89645 1.56622 2.03585C1.43234 2.17149 1.24267 2.2393 0.997212 2.2393C0.751758 2.2393 0.562088 2.17149 0.428204 2.03585C0.298039 1.89645 0.232956 1.72315 0.232956 1.51593Z"
        fill="black"
      />
    </svg>
  </Icon>
);

export const IconText: React.FC<{
  children: React.ReactNode;
  bg?: string;
}> = ({ children, bg }) => (
  <Icon bg={bg}>
    <CircleText>{children}</CircleText>
  </Icon>
);

export const GreyBlockTitle: FC<Props> = ({
  title,
  icon,
  ...restProps
}: Props) => (
  <GreyBlockTitleContainer {...restProps}>
    {icon || null}
    <TitleText>{title}</TitleText>
  </GreyBlockTitleContainer>
);
