/* eslint-disable max-lines */
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { set } from "date-fns";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import * as yup from "yup";
import useAuthSession from "../../../authentication/hooks/useAuthSession";
import Button from "../../../lib/Button";
import FormField from "../../../lib/forms/FormField";
import Input from "../../../lib/forms/primitives/Input";
import Radio from "../../../lib/forms/primitives/Radio";
import Select from "../../../lib/forms/primitives/Select";
import { useMessagesApi } from "../../../lib/messages";
import Title from "../../../lib/Title";
import { JSONApiErrorsException } from "../../../network/jsonApi/core/JSONApiErrorsPayload";
import { ProfileEntityWithRelations } from "../../../network/jsonApiV2/models/specific/Profile";
import { mapJSONApiErrors } from "../../../utils/errors";
import {
  DAYS_RECORD,
  DAYS,
  YEARS_RECORD,
  YEARS,
  GENDERS,
} from "../../../utils/formOptions";
import { useHandleSubmitImpl } from "../../../utils/forms";
import getMonthsForLocale from "../../../utils/getMonthsForLocale";
import mediaQuery from "../../../utils/mediaQuery";
import { range } from "../../../utils/range";
import { useAutofocusElementId } from "../../../utils/scroll";
import { useUpdateProfile } from "../hooks";
import { Comment } from "../Profile/style";

const getResolver = () => {
  const FormSchema = yup.object().shape({
    day: yup
      .number()
      .oneOf(DAYS.map((m) => m.value))
      .nullable()
      .required(t`Please select a day`),
    email: yup
      .string()
      .trim()
      .email(t`Email must be a valid email`)
      .required(t`Please enter your email`),
    memberId: yup.string().trim(),
    month: yup
      .number()
      .oneOf(range(1, 12))
      .nullable()
      .required(t`Please select a month`),
    personalEmail: yup
      .string()
      .trim()
      .email(t`Personal email must be a valid email`),
    preferredName: yup.string().trim().notRequired(),
    year: yup
      .number()
      .oneOf(YEARS.map((m) => m.value))
      .nullable()
      .required(t`Please select a year`),
    zip: yup
      .string()
      .trim()
      .required(t`Please enter your zip code`),
  });
  return yupResolver(FormSchema);
};

type Props = {
  data: ProfileEntityWithRelations;
};

interface FormProps {
  memberId: string;
  email: string;
  zip: string;
  personalEmail: string;
  preferredName: string;
  month: number;
  day: number;
  year: number;
  gender: "female" | "male" | "no_answer" | "other";
}

const Container = styled.div`
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: 420px;
    `,
  )}
`;
const StyledFormField = styled(FormField)``;

const StyledForm = styled.form`
  ${StyledFormField} {
    margin-top: 30px;
  }
`;

const RadioFormField = styled(StyledFormField)`
  fieldset {
    flex-wrap: wrap;
    column-gap: 53px;
    row-gap: 16px;
  }

  legend {
    width: 100%;
  }
`;

const DobSpacer = styled.div`
  column-gap: 16px;
  display: grid;
  grid-template:
    "month month" auto
    "day year" auto
    / 1fr 1fr;
  row-gap: 12px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      grid-template: "month day year" auto / 1fr auto auto;
    `,
  )}
`;

const MarginButton = styled(Button)`
  margin-top: 30px;
`;

const EditProfileForm: FC<Props> = (props) => {
  const navigate = useNavigate();

  const { data } = props;

  const resolver = useMemo(() => getResolver(), []);
  const {
    mutate: updateProfile,
    isLoading,
    error: mutationError,
  } = useUpdateProfile();

  const { data: authSession } = useAuthSession();
  const isUS: boolean =
    authSession?.user?.company?.attributes?.country_code === "US";

  const focus = useAutofocusElementId();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setError,
  } = useForm<FormProps>({
    resolver,
  });

  useEffect(() => {
    if (mutationError instanceof JSONApiErrorsException) {
      const parsedErrors = mapJSONApiErrors(mutationError.errors, {
        email: "data/attributes/email",
        gender: "data/attributes/gender",
        memberId: "data/attributes/member_id",
        personalEmail: "data/attributes/personal_email",
        preferredName: "data/attributes/preferred_name",
        zip: "data/attributes/zip",
      });

      for (const entry of Object.entries(parsedErrors)) {
        const error = entry[1];
        const key = entry[0] as keyof typeof parsedErrors;
        if (error?.title) {
          setError(key, {
            message: error.title,
          });
        }
      }
    }
  }, [mutationError, setError]);

  const messagesApi = useMessagesApi();
  const onSubmit = useCallback(
    ({
      memberId,
      email,
      zip,
      personalEmail,
      preferredName,
      day,
      month,
      year,
      gender,
    }: FormProps) => {
      const dob = set(new Date(), {
        date: day,
        month: month - 1,
        year,
      });

      updateProfile(
        {
          dob,
          email,
          gender,
          member_id: memberId,
          personal_email: personalEmail,
          preferred_name: preferredName,
          zip,
        },
        {
          onError: (...args) => {
            // eslint-disable-next-line no-console
            console.error(...args);
            messagesApi.error({
              content: t`Error updating profile`,
            });
          },
          onSuccess: (response, passedProps) => {
            if (passedProps.email !== data.attributes.email) {
              messagesApi.success({
                content: t`You will receive an email with instructions for how to confirm your new email address shortly`,
              });
            } else {
              messagesApi.success({
                content: t`Profile updated`,
              });
            }

            navigate("/profile");
          },
        },
      );
    },
    [updateProfile, messagesApi, data.attributes.email, navigate],
  );

  const { handler: handleSubmitImpl } = useHandleSubmitImpl(
    handleSubmit,
    onSubmit,
  );

  const MONTHS = getMonthsForLocale().map((item, index) => ({
    label: item,
    value: index + 1,
  }));
  const MONTHS_RECORD = Object.fromEntries(
    MONTHS.map((o) => [o.value, o]),
  );

  const { i18n } = useLingui();
  return (
    <Container>
      <Title level="h2">
        <Trans>Edit profile</Trans>
      </Title>
      <StyledForm onSubmit={handleSubmitImpl}>
        <StyledFormField
          error={errors.email?.message}
          label={t`Email`}
        >
          <Input
            {...register("email")}
            autoComplete="email"
            defaultValue={data.attributes.email}
            type="text"
          />
        </StyledFormField>

        <StyledFormField
          error={errors.memberId?.message}
          label={t`Member ID`}
          style={{
            display:
              data.attributes.ask_member_id ||
              !!data.attributes.member_id
                ? undefined
                : "none",
          }}
        >
          <Input
            {...register("memberId")}
            autoComplete="member-id"
            defaultValue={data.attributes.member_id || ""}
            type="text"
          />

          <Comment>
            <Trans>
              If you&lsquo;re enrolled in the medical plan, the ID is
              on your primary health insurance medical card.
              Otherwise, leave this blank.
            </Trans>
          </Comment>
        </StyledFormField>
        <StyledFormField
          error={errors.preferredName?.message}
          label={t`Preferred name`}
        >
          <Input
            {...register("preferredName")}
            autoComplete="nickname"
            defaultValue={data.attributes.preferred_name}
            type="text"
          />
        </StyledFormField>

        <StyledFormField
          error={(errors.month || errors.day || errors.year)?.message}
          formGroup
          label={t`Date of Birth`}
        >
          <DobSpacer>
            <Controller
              control={control}
              defaultValue={
                MONTHS_RECORD[data.attributes.dob.getMonth() + 1]
                  ?.value
              }
              name="month"
              render={({
                field: { onBlur, onChange, value, ref },
                fieldState,
              }) => (
                <Select
                  ref={ref}
                  aria-errormessage={errors.month?.message}
                  aria-invalid={fieldState.invalid}
                  aria-label={t`Month`}
                  css="grid-area: month;"
                  isMulti={false}
                  onBlur={onBlur}
                  onChange={(option) => onChange(option?.value)}
                  options={MONTHS}
                  placeholder={t`Month`}
                  value={MONTHS.find((m) => m.value === value)}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={
                DAYS_RECORD[data.attributes.dob.getDate()]?.value
              }
              name="day"
              render={({
                field: { onBlur, onChange, value, ref },
                fieldState,
              }) => (
                <Select
                  ref={ref}
                  aria-errormessage={errors.day?.message}
                  aria-invalid={fieldState.invalid}
                  aria-label={t`Day`}
                  css="grid-area: day;"
                  isMulti={false}
                  onBlur={onBlur}
                  onChange={(option) => onChange(option?.value)}
                  options={DAYS}
                  placeholder={t`Day`}
                  value={DAYS_RECORD[value]}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={
                YEARS_RECORD[data.attributes.dob.getFullYear()]?.value
              }
              name="year"
              render={({
                field: { onBlur, onChange, value, ref },
                fieldState,
              }) => (
                <Select
                  ref={ref}
                  aria-errormessage={errors.year?.message}
                  aria-invalid={fieldState.invalid}
                  aria-label={t`Year`}
                  css="grid-area: year;"
                  isMulti={false}
                  onBlur={onBlur}
                  onChange={(option) => {
                    onChange(option?.value);
                  }}
                  options={YEARS}
                  placeholder={t`Year`}
                  value={YEARS_RECORD[value]}
                />
              )}
            />
          </DobSpacer>
        </StyledFormField>

        <RadioFormField
          error={errors.gender?.message}
          formGroup
          label={t`Gender identity`}
          layout="row"
        >
          {GENDERS.map(({ label, value }) => (
            <Radio
              key={value}
              defaultChecked={data.attributes.gender === value}
              label={i18n._(label)}
              value={value}
              {...register("gender")}
            />
          ))}
        </RadioFormField>

        <StyledFormField
          error={errors.zip?.message}
          label={isUS ? t`ZIP` : t`Postal code`}
        >
          <Input
            {...register("zip")}
            autoComplete="zip"
            defaultValue={data.attributes.zip}
            type="text"
          />
        </StyledFormField>

        <StyledFormField
          error={errors.personalEmail?.message}
          label={t`Personal email`}
        >
          <Input
            {...register("personalEmail")}
            autoComplete="personal-email"
            autoFocus={focus === "personal_email"}
            defaultValue={data.attributes.personal_email || ""}
            type="text"
          />
        </StyledFormField>
        <Comment>
          <Trans>
            Recovery email to restore access to your account
          </Trans>
        </Comment>

        <MarginButton
          disabled={isLoading}
          kind="filledCoral"
          resetWidth
          type="submit"
        >
          <Trans>Update</Trans>
        </MarginButton>
      </StyledForm>
    </Container>
  );
};

export default EditProfileForm;
