import { Trans } from "@lingui/macro";
import { useMemo } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants/colors";
import mediaQuery from "../../utils/mediaQuery";
import FamilyStatus from "./FamilyStatus";
import {
  fertilityCareStatuses,
  hormoneCareStatuses,
  ProfileStatus,
} from "./types";

const Statuses = styled.div`
  display: grid;
  gap: 4px;
  padding: 0;
  grid-template-columns: 1fr 1fr 1fr;

  ${mediaQuery(
    "greaterThanTablet",
    css`
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    `,
  )}
`;

const StatusGroup = styled.div`
  & + & {
    margin-top: 30px;
  }
`;
const GroupTitle = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.brownBlack};
  font-weight: 700;
  text-align: center;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      text-align: left;
    `,
  )}
`;

interface Props {
  availableStatuses: readonly ProfileStatus[];
  onChange: (status: ProfileStatus) => void;
  status: ProfileStatus | null;
}

const FamilyStatusForm = ({
  availableStatuses,
  onChange,
  status,
}: Props) => {
  const fertilityStatusesFiltered = useMemo(
    () =>
      fertilityCareStatuses.filter((item) =>
        availableStatuses.includes(item),
      ),
    [availableStatuses],
  );

  const hormoneStatusesFiltered = useMemo(
    () =>
      hormoneCareStatuses.filter((item) =>
        availableStatuses.includes(item),
      ),
    [availableStatuses],
  );

  const hasTwoStatusGroups =
    fertilityStatusesFiltered.length > 0 &&
    hormoneStatusesFiltered.length > 0;

  return (
    <>
      {fertilityStatusesFiltered.length > 0 && (
        <StatusGroup>
          {hasTwoStatusGroups && (
            <GroupTitle>
              <Trans>Reproductive Care</Trans>
            </GroupTitle>
          )}
          <Statuses>
            {fertilityStatusesFiltered.map((s) => (
              <FamilyStatus
                key={s}
                isActive={s === status}
                onClick={() => onChange(s)}
                status={s}
                type="button"
              />
            ))}
          </Statuses>
        </StatusGroup>
      )}

      {hormoneStatusesFiltered.length > 0 && (
        <StatusGroup>
          {hasTwoStatusGroups && (
            <GroupTitle>
              <Trans>Hormone Care</Trans>
            </GroupTitle>
          )}
          <Statuses>
            {hormoneStatusesFiltered.map((s) => (
              <FamilyStatus
                key={s}
                isActive={s === status}
                onClick={() => onChange(s)}
                status={s}
                type="button"
              />
            ))}
          </Statuses>
        </StatusGroup>
      )}
    </>
  );
};

export default FamilyStatusForm;
