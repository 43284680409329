import { Trans } from "@lingui/macro";
import React, { useState, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import Button from "../../lib/Button";
import Title from "../../lib/Title";
import { languageSwitcherLocalesMap } from "../../locales/appLocale/config";
import mediaQuery from "../../utils/mediaQuery";
import BookAppointmentForm from "./BookAppointmentForm";
import { ClinicEntityWithRelations } from "./models/Clinic";

const Container = styled.div`
  padding: 20px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 30px 40px;
    `,
  )}
`;

const StyledTitle = styled(Title)`
  margin: 0;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: auto;
    `,
  )}
`;

const StyledLink = styled.a`
  font-size: 15px;
  line-height: 24px;
  color: ${colors.blue};
`;

const Header = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${colors.brownLightGrey1};
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      flex-direction: row;
      flex-wrap: nowrap;
      padding-bottom: 30px;
      margin-bottom: 30px;
    `,
  )}
`;

const HeaderText = styled.div`
  flex-grow: 1;
`;

const Image = styled.div`
  width: 60px;
  height: 60px;
  background: #eee;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const Text = styled.div`
  font-size: 15px;
  line-height: 24px;
`;

const Certificates = styled(Text)`
  margin-bottom: 4px;
`;

const Subtitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.brownGrey};
`;

const Info = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      flex-direction: row;
    `,
  )}
`;

const Bio = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: auto;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      min-width: 193px;
    `,
  )}
`;

const BioTitle = styled(Text)`
  font-weight: 700;
  color: ${colors.black};
`;

const LayoutDoula: React.FC<ClinicEntityWithRelations> = ({
  attributes,
  id,
  relationships,
}: ClinicEntityWithRelations) => {
  const [showBookAppointment, setBookAppointment] = useState(false);

  const [firstLocation] = relationships.locations;

  const doctor = useMemo(
    () => relationships.doctors[0],
    [relationships],
  );

  const spokenLanguages = doctor?.attributes.languages_spoken
    .filter(
      (code): code is keyof typeof languageSwitcherLocalesMap =>
        code in languageSwitcherLocalesMap,
    )
    .map((code) => languageSwitcherLocalesMap[code].getLabel())
    .join(", ");

  const onCancelAppointmentClick = useCallback(() => {
    setBookAppointment(false);
  }, []);

  return (
    <>
      {doctor && (
        <Container>
          <Header>
            <Image>
              {doctor.attributes.image_set && (
                <img
                  alt={doctor.attributes.name}
                  src={doctor.attributes.image_set.w256}
                  srcSet={`${doctor.attributes.image_set.w64} 64w, ${doctor.attributes.image_set.w128} 128w, ${doctor.attributes.image_set.w256} 256w, ${doctor.attributes.image_set.w512} 512w`}
                />
              )}
            </Image>

            <HeaderText>
              <StyledTitle level="h3">
                {doctor.attributes.name}
              </StyledTitle>

              <Certificates>
                {doctor.attributes.certification}
              </Certificates>

              <Subtitle>
                {firstLocation.attributes.approximate_location ||
                  firstLocation.attributes.address}
              </Subtitle>
            </HeaderText>

            {!showBookAppointment && (
              <StyledButton
                kind="filledGreen"
                onClick={() => setBookAppointment(true)}
              >
                <Trans>Book Appointment</Trans>
              </StyledButton>
            )}
          </Header>

          <Info>
            <Bio>
              <div>
                <BioTitle>
                  <Trans>Race/ethnicity</Trans>
                </BioTitle>
                <Text>{doctor.attributes.ethnicity}</Text>
              </div>

              <div>
                <BioTitle>
                  <Trans>Communities supported</Trans>
                </BioTitle>
                <Text>{doctor.attributes.community}</Text>
              </div>

              <div>
                <BioTitle>
                  <Trans>Languages</Trans>
                </BioTitle>
                <Text>{spokenLanguages}</Text>
              </div>

              {attributes.url && (
                <div>
                  <StyledLink
                    href={attributes.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Website
                  </StyledLink>
                </div>
              )}
            </Bio>

            <Text>{doctor.attributes.biography}</Text>
          </Info>

          {showBookAppointment && (
            <BookAppointmentForm
              clinicId={id}
              isDoula
              onCancel={onCancelAppointmentClick}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default LayoutDoula;
