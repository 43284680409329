/* eslint-disable no-underscore-dangle */
import { i18n } from "@lingui/core";
import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { isRight } from "fp-ts/Either";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { QueryClient, useQueryClient } from "react-query";
import Rollbar from "rollbar";
import toCommonLocale from "../locales/appLocale/config/toCommonLocale";
import { rollbarConfig } from "../rollbarConfig";
import JSONApiErrorsPayloadC, {
  JSONApiErrorsException,
} from "./jsonApi/core/JSONApiErrorsPayload";
import QueryClientProviderBound from "./QueryClientBound";

const AxiosContext = createContext<AxiosInstance>(Axios);

export const AxiosProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const queryClient = useQueryClient();
  const axios = useMemo(
    () => constructAxios(queryClient),
    [queryClient],
  );

  return (
    <AxiosContext.Provider value={axios}>
      {children}
    </AxiosContext.Provider>
  );
};

export function useAxios(): AxiosInstance {
  return useContext(AxiosContext);
}

function prefillXCSRFToken<T extends AxiosRequestConfig>(
  queryClient: QueryClient,
  config: T,
): T {
  try {
    const cookieRequestCache = queryClient
      .getQueryCache()
      .find("cookie");
    if (cookieRequestCache) {
      const { state } = cookieRequestCache;
      const cookieData = state.data as
        | {
            csrfToken?: string | null;
          }
        | undefined;
      if (cookieData?.csrfToken) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,no-param-reassign
        config.headers = {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(config.headers as any),
          "X-CSRF-Token": cookieData.csrfToken,
        };
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return config;
}
export function constructAxios(
  queryClient: QueryClient,
): AxiosInstance {
  const mswEnabled =
    import.meta.env.NODE_ENV === "test" ||
    import.meta.env.VITE_MSW_ENABLED === "enabled" ||
    !!import.meta.env.STORYBOOK;
  const baseURL = mswEnabled
    ? import.meta.env.VITE_BACKEND
    : `${import.meta.env.VITE_API_HOST || ""}/api`;
  // eslint-disable-next-line no-console
  const axios = Axios.create({
    baseURL,
    headers: {
      "X-Language": toCommonLocale(i18n.locale),
    },
    withCredentials: mswEnabled ? undefined : true,
  });
  const XCSRFTokenInterceptor = <T extends AxiosRequestConfig>(
    config: T,
  ): T => prefillXCSRFToken(queryClient, config);
  const ErrorsParser = (error: unknown) => {
    if (Axios.isAxiosError(error) && error.response) {
      if (
        error.response.status !== 401 &&
        error.response.status !== 422
      ) {
        const rollbarInstance = new Rollbar(rollbarConfig);
        rollbarInstance.warn("unrecognized error", error);
      }

      const decoded = JSONApiErrorsPayloadC.decode(
        error.response.data,
      );
      if (isRight(decoded)) {
        return Promise.reject(
          new JSONApiErrorsException(
            error.message,
            decoded.right.errors,
            error.response.status,
          ),
        );
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
  axios.interceptors.request.use(XCSRFTokenInterceptor);
  axios.interceptors.response.use(undefined, ErrorsParser);

  return axios;
}
export { QueryClientProviderBound };
