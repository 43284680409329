/* eslint-disable max-lines */
import type { MessageDescriptor } from "@lingui/core";
import { Trans, defineMessage } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC, useCallback, useState, useEffect, useRef } from "react";
import AnimateHeight from "react-animate-height";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../constants";
import { Title } from "../../../../lib";
import { useMessagesApi } from "../../../../lib/messages";
import Icon from "../../../../lib/SharedIcon";
import mediaQuery from "../../../../utils/mediaQuery";
import renderError from "../../../../utils/renderError";
import FamilyStatusForm from "../../../FamilyStatusForm";
import { FamilyStatusIcon } from "../../../FamilyStatusForm/FamilyStatus";
import { ProfileStatus } from "../../../FamilyStatusForm/types";
import { useUpdateFamilyStatus } from "../../../FamilyStatusForm/useUpdateFamilyStatus";
import { IconClose } from "./IconClose";

const shortInfoMessages: Record<ProfileStatus, MessageDescriptor> = {
  expecting_a_baby: defineMessage({
    message: "You shared with us that you’re pregnant.",
  }),
  gender_affirming_care: defineMessage({
    message:
      "You shared with us that you are interested in gender affirming care.",
  }),
  having_a_newborn: defineMessage({
    message: "You shared with us that you have a newborn.",
  }),
  menopause: defineMessage({
    message:
      "You shared with us that you are interested in menopause care.",
  }),
  mens_optimization: defineMessage({
    message:
      "You shared with us that you are interested in men’s optimization care.",
  }),
  planning_later: defineMessage({
    message:
      "You shared with us that you want to have a child later.",
  }),
  planning_now: defineMessage({
    message: "You shared with us that you want to have a child now.",
  }),
  working_parent: defineMessage({
    message: "You shared with us that you’re a working parent.",
  }),
};

const fadeTransitionName = "FadeContentLayer";
const fadeTransitionDuration = 200;
const heightAnimationDuration = 300;

const FadeContentLayer = styled.div`
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  ${mediaQuery(
    "desktopMedium",
    css`
      left: 240px;
      width: calc(100% - 240px);
    `,
  )}
`;

const Container = styled.div`
  width: 100%;
  ${mediaQuery(
    "greaterThanTablet",
    css`
      width: auto;
    `,
  )}

  .${fadeTransitionName}-enter {
    opacity: 0;
  }
  .${fadeTransitionName}-enter-active {
    opacity: 1;
    transition: opacity ${fadeTransitionDuration}ms;
  }
  .${fadeTransitionName}-exit {
    opacity: 1;
  }
  .${fadeTransitionName}-exit-active {
    opacity: 0;
    transition: opacity ${fadeTransitionDuration}ms;
  }
`;

const openButtonIconCss = css`
  height: 30px;
  width: 30px;
`;

const IconContainer = styled.div<{ $isOpen: boolean }>`
  flex-shrink: 0;
  inset-inline-end: 20px;

  ${openButtonIconCss};

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      position: absolute;
    `}

  ${mediaQuery(
    "greaterThanTablet",
    css`
      top: 56px;
    `,
  )}
`;

const StyledFamilyStatusIcon = styled(FamilyStatusIcon)`
  ${openButtonIconCss};
  position: absolute;
  top: 0;
`;

const CloseIcon = styled(IconClose)`
  ${openButtonIconCss};
  background: #fff;
  color: ${colors.coral};
  cursor: pointer;
  font-size: 30px;
  opacity: 0;
  position: absolute;
  top: 0;

  &:hover {
    color: ${colors.coralHover};
  }
`;

const StatusText = styled.span`
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 15px;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 15px;
`;

const Summary = styled.div<{ $isOpen: boolean }>`
  align-items: flex-start;
  cursor: pointer;
  display: inline-flex;
  gap: 10px;
  justify-content: flex-end;
  list-style: none;

  ${mediaQuery(
    "greaterThanTablet",
    css`
      flex-direction: row-reverse;
    `,
  )}

  &:hover {
    color: ${colors.blue};
  }

  &::marker,
  &:after,
  &::-webkit-details-marker {
    content: "";
    display: none;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      ${mediaQuery(
        "phone",
        css`
          padding-inline-end: 40px;
        `,
      )}
    `}
`;

const Statuses = styled.div`
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;

  ${mediaQuery(
    "desktopMedium",
    css`
      column-gap: 59px;
      flex-wrap: nowrap;
      justify-content: flex-start;
    `,
  )}
`;

const Comment = styled.div`
  align-items: flex-start;
  color: ${colors.brownGreyStable};
  display: flex;
  font-family: ${fonts.main}, sans-serif;
  font-size: 12px;
  gap: 6px;
  line-height: 130%;
  margin-top: 30px;
`;

const Details = styled.div<{ $isOpen: boolean }>`
  overflow: hidden;
  position: relative;

  max-height: calc(100vh - 30px);

  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 -20px 0 -20px;

  z-index: ${({ $isOpen }) => ($isOpen ? 16 : undefined)};
  transition: background 0.3s ease;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      ${mediaQuery(
        "phone",
        css`
          top: -60px;
          padding-top: 60px;
          background: #fff;
          padding-bottom: 20px;
        `,
      )}

      ${mediaQuery(
        "greaterThanPhone",
        css`
          margin-bottom: 60px;
        `,
      )}

      & > ${Summary} {
        ${StyledFamilyStatusIcon} {
          opacity: 0;
        }
        ${CloseIcon} {
          opacity: 1;
        }
        ${StatusText} {
          opacity: 0;
        }
      }
    `}

  ${Title} {
    margin-bottom: 30px;
  }

  ${Statuses} {
    margin-bottom: 30px;
  }
`;

const StyledTitle = styled(Title)`
  ${mediaQuery(
    "greaterThanTablet",
    css`
      margin-top: 20px;
    `,
  )}

  ${mediaQuery(
    "phone",
    css`
      text-align: center;
      font-size: 24px;
    `,
  )}
`;

interface Props {
  currentStatus: ProfileStatus;
  availableStatuses: readonly ProfileStatus[];
}

const ChangeFamilyStatus: FC<Props> = ({
  availableStatuses,
  currentStatus,
  ...restProps
}) => {
  const detailsRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { mutate: updateFamilyStatus, isLoading } =
    useUpdateFamilyStatus();
  const messages = useMessagesApi();
  const handleClick = useCallback(
    (selectedStatus: ProfileStatus) => {
      if (isLoading) return;
      updateFamilyStatus(selectedStatus, {
        onError: (error) => {
          messages.error({
            content: renderError(error),
          });
        },
        onSuccess: () => {
          setIsOpen(false);
        },
      });
    },
    [messages, updateFamilyStatus, isLoading],
  );

  const onTitleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
      setTimeout(() => {
        if (detailsRef.current) {
          detailsRef.current.style.overflowY = "auto";
        }
      }, heightAnimationDuration);
    } else {
      document.body.style.overflowY = "";
      if (detailsRef.current) {
        detailsRef.current.style.overflowY = "hidden";
      }
    }
  }, [isOpen]);

  const { i18n } = useLingui();

  return (
    <Container>
      <CSSTransition
        classNames={fadeTransitionName}
        in={isOpen}
        timeout={fadeTransitionDuration}
        unmountOnExit
      >
        <FadeContentLayer onClick={() => setIsOpen(false)} />
      </CSSTransition>
      <Details
        {...restProps}
        ref={detailsRef}
        $isOpen={isOpen}
        role="group"
      >
        <Summary $isOpen={isOpen} onClick={onTitleClick}>
          <StatusText>
            {i18n._(shortInfoMessages[currentStatus])}
          </StatusText>
          <IconContainer $isOpen={isOpen}>
            <StyledFamilyStatusIcon status={currentStatus} />
            <CloseIcon />
          </IconContainer>
        </Summary>
        <AnimateHeight
          duration={heightAnimationDuration}
          easing="ease-in-out"
          height={isOpen ? "auto" : 0}
        >
          <StyledTitle level="h2" style={{ paddingTop: "5px" }}>
            <Trans>Change your current status?</Trans>
          </StyledTitle>

          <FamilyStatusForm
            availableStatuses={availableStatuses}
            onChange={handleClick}
            status={currentStatus}
          />

          <Comment>
            <Icon type="lock" />{" "}
            <Trans>
              This information will always stay private and you can
              always change your status later
            </Trans>
          </Comment>
        </AnimateHeight>
      </Details>
    </Container>
  );
};

export default ChangeFamilyStatus;
