import { t, Trans } from "@lingui/macro";
import { FC } from "react";
import {
  GreyBlock,
  GreyBlockTitle,
  IconInfo,
} from "../../../../lib/GreyBlock";
import Link from "../../../../lib/Link";

export const WhyAmIGettingThisBill: FC = () => (
  <GreyBlock>
    <GreyBlockTitle
      icon={<IconInfo />}
      title={t`Why am I getting this bill?`}
    />
    <p>
      <Trans>
        You do not need to pay this bill to our in-network providers
        when receiving care or Rx. Instead, Stork Club makes it easy
        to pay your responsibility (e.g., deductible and/or
        coinsurance) via this secure system. This payment contributes
        to your deductible (if applicable) and out-of-pocket max for
        your medical plan.
      </Trans>
    </p>
    <p>
      <Trans>
        If you have questions about this bill, please contact your{" "}
        <Link to="/care-navigator">Care Navigator</Link>
      </Trans>
    </p>
  </GreyBlock>
);
