import { t } from "@lingui/macro";
import { useGetArticlesRecent } from "../hooks/useGetArticles";
import Items from "../Items";

const RecentBlock = () => {
  const { data, isLoading } = useGetArticlesRecent();

  return data?.length ? (
    <Items
      data={data}
      isLoading={isLoading}
      title={t`Recent Articles`}
    />
  ) : null;
};

export default RecentBlock;
