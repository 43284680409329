import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import React, { FC, useCallback, useMemo, useRef } from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import * as yup from "yup";
import ReCAPTCHA from "../../../components/ReCAPTCHA";
import { colors } from "../../../constants";
import Alert from "../../../lib/Alert";
import FormField from "../../../lib/forms/FormField";
import Input from "../../../lib/forms/primitives/Input";
import Icon from "../../../lib/Icon";
import { useHandleSubmitImpl } from "../../../utils/forms";
import mediaQuery from "../../../utils/mediaQuery";
import renderError from "../../../utils/renderError";
import Info from "../Info";
import Layout from "../Layout";
import imgSrc from "../Layout/signin-bg2-a73bb9ef0fc46cb003538bafd272607d.jpeg";
import { Header, StyledButton } from "../styles";
import { useForgotPassword } from "./hooks";

const StyledForm = styled.form`
  ${StyledButton} {
    margin-top: 24px;
    ${mediaQuery(
      "desktopMedium",
      css`
        width: auto;
      `,
    )}
  }
`;
const SignInLink = styled(Link)`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  text-decoration: none;
`;

const SignInLinkText = styled.div`
  color: ${colors.blue};
  display: block;
  font-size: 13px;
  line-height: 16px;
  margin-inline-start: 10px;
`;

const StyledLayout = styled(Layout)`
  ${Header} {
    display: block;
    margin-bottom: 30px;
  }
  ${StyledForm} {
    margin-top: 30px;
    ${mediaQuery(
      "desktopMedium",
      css`
        margin-top: 40px;
        max-width: 355px;
      `,
    )}
  }
`;

const getResolver = () => {
  const FormSchema = yup.object().shape({
    email: yup
      .string()
      .email(t`Please enter a valid email address`)
      .required(t`Email required`),
  });

  return yupResolver(FormSchema);
};
export interface FormProps {
  email: string;
}
const ForgotPasswordScreen: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isLoading,
    error: externalError,
    mutate: askRestorePassword,
  } = useForgotPassword();

  const { email: locationEmail = "" } =
    (location.state as
      | {
          email?: string;
        }
      | undefined) || {};

  const resolver = useMemo(() => getResolver(), []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      email: locationEmail,
    },
    resolver,
  });
  const recaptchaRef = useRef<ReCAPTCHABase>(null);
  const onSubmit = useCallback(
    ({
      email,
      recaptchaToken,
    }: {
      email: string;
      recaptchaToken: string;
    }) => {
      askRestorePassword(
        { email, recaptchaToken },
        {
          onSuccess: () => {
            navigate("/signin", {
              state: {
                email,
                fromRecovery: true,
              },
            });
          },
        },
      );
    },
    [askRestorePassword, navigate],
  );
  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);
  const error = externalError || internalError;

  return (
    <StyledLayout imgSrc={imgSrc}>
      {!!error && <Alert status="error">{renderError(error)}</Alert>}
      <>
        <SignInLink to="/signin">
          <Icon
            fill={colors.blue}
            height={16}
            type="back"
            viewBox="0 0 24 24"
            width={16}
          />
          <SignInLinkText>
            <Trans>Sign in</Trans>
          </SignInLinkText>
        </SignInLink>
        <Header>
          <Trans>Forgot password</Trans>
        </Header>

        <StyledForm onSubmit={handleSubmitImpl}>
          <FormField
            description={t`We will send you a recovery email`}
            error={errors.email?.message}
            hideLabel
            label={t`Email`}
          >
            <Input
              {...register("email")}
              $activeColor={colors.blueGreen}
              autoComplete="username"
              placeholder={t`Email`}
              type="email"
            />
          </FormField>
          <StyledButton
            disabled={isLoading}
            kind="filledBlueGreen"
            type="submit"
          >
            <Trans>Send reset link</Trans>
          </StyledButton>
        </StyledForm>
        <Info />
      </>
      <ReCAPTCHA ref={recaptchaRef} />
    </StyledLayout>
  );
};

export default ForgotPasswordScreen;
