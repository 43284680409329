import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";
import * as yup from "yup";
import ReCAPTCHA from "../../../../components/ReCAPTCHA";
import { colors } from "../../../../constants";
import Alert from "../../../../lib/Alert";
import FormField from "../../../../lib/forms/FormField";
import Input from "../../../../lib/forms/primitives/Input";
import PageTitle from "../../../../lib/PageTitle";
import { JSONApiErrorsException } from "../../../../network/jsonApi/core/JSONApiErrorsPayload";
import { mapJSONApiErrors } from "../../../../utils/errors";
import { useHandleSubmitImpl } from "../../../../utils/forms";
import mediaQuery from "../../../../utils/mediaQuery";
import renderError from "../../../../utils/renderError";
import Info from "../../Info";
import { EmailBlock, Header, StyledButton } from "../../styles";
import ChangeEmailBlock from "../ChangeEmailBlock";
import { useConfirm2FA } from "./hooks";

const getResolver = () => {
  const FormSchema = yup.object().shape({
    code: yup
      .string()
      .trim()
      .length(6, t`Code must be 6 characters`)
      .required(t`Code required`),
  });

  return yupResolver(FormSchema);
};

const StyledAlert = styled(Alert)``;

const StyledForm = styled.form`
  ${mediaQuery(
    "desktopMedium",
    css`
      max-width: 355px;
    `,
  )}
  ${StyledButton} {
    margin-top: 24px;
  }
  .labelText {
    color: ${colors.brownBlack};
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 8px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${StyledAlert} {
    display: block;
    margin-bottom: 8px;
  }
  ${Header} {
    margin-bottom: 40px;
  }

  ${StyledForm} {
    margin-top: 42px;
  }
`;

interface FormProps {
  code: string;
}

export interface TwoFactorCodeFormProps {
  email: string;
  verificationToken: string;
  onChangeEmail: () => void;
  onSignedIn: () => void;
}
const TwoFactorCodeForm: FC<TwoFactorCodeFormProps> = (props) => {
  const { email, verificationToken, onChangeEmail, onSignedIn } =
    props;

  const {
    isLoading,
    error: externalError,
    mutate: checkCode,
    data,
  } = useConfirm2FA();

  const resolver = useMemo(() => getResolver(), []);
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      code: "",
    },

    resolver,
  });

  useEffect(() => {
    if (externalError instanceof JSONApiErrorsException) {
      const parsedErrors = mapJSONApiErrors(externalError.errors, {
        code: "data/attributes/code",
      });

      for (const entry of Object.entries(parsedErrors)) {
        const error = entry[1];
        const key = entry[0] as keyof typeof parsedErrors;
        if (error?.title) {
          setError(key, {
            message: error.title,
          });
        }
      }
    }
  }, [externalError, setError]);

  useEffect(() => {
    if (data === true) {
      onSignedIn();
    }
  }, [data, onSignedIn]);

  const recaptchaRef = useRef<ReCAPTCHABase>(null);

  const onSubmit = useCallback(
    ({
      code,
      recaptchaToken,
    }: {
      code: string;
      recaptchaToken: string;
    }) => {
      checkCode({ code, email, recaptchaToken, verificationToken });
    },
    [checkCode, email, verificationToken],
  );

  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);
  const error = externalError || internalError;

  return (
    <Container>
      {!!error && (
        <StyledAlert status="error">{renderError(error)}</StyledAlert>
      )}
      <PageTitle>Enter 2FA code</PageTitle>
      <ChangeEmailBlock onChangeEmail={onChangeEmail} />
      <Header>
        <Trans>Welcome back</Trans>
      </Header>
      <EmailBlock>{email}</EmailBlock>
      <StyledForm onSubmit={handleSubmitImpl}>
        <FormField
          error={errors.code?.message}
          label={t`Please check your email for an authentication code and enter it here`}
          labelTextClassName="labelText"
        >
          <Input
            {...register("code")}
            $activeColor={colors.blueGreen}
            autoComplete="one-time-code"
            inputMode="numeric"
            placeholder={t`Verification code`}
            type="text"
          />
        </FormField>
        <StyledButton
          disabled={isLoading}
          kind="filledBlueGreen"
          type="submit"
        >
          <Trans>Check code</Trans>
        </StyledButton>
      </StyledForm>
      <ReCAPTCHA ref={recaptchaRef} />
      <Info />
    </Container>
  );
};

export default TwoFactorCodeForm;
