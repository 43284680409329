import { FC, HTMLAttributes, ReactNode } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants";
import mediaQuery from "../../utils/mediaQuery";
import ScrollHandler from "../ScrollHandler";
import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

/**
 * Text content page should have up to 650px width, while default page should have up to 800px
 */
type LayoutMode = "default" | "textContent";
const Container = styled.div<{ $layoutMode: LayoutMode }>`
  --content-max-width: ${({ $layoutMode }) =>
    $layoutMode === "textContent" ? "650px" : undefined};
  --sidebar-width: 240px;
  ${mediaQuery(
    "phone",
    css`
      grid-template-areas:
        "header"
        "body"
        "footer";
      grid-template-columns: 100%;
    `,
  )}

  background-color: ${colors.brownLightGrey2};
  display: grid;
  grid-template-areas:
    "sidebar header"
    "sidebar body"
    "sidebar footer";
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr min-content;
  min-height: 100vh;
  ${Header} {
    grid-area: header;
  }
  ${Body} {
    grid-area: body;
  }
  ${Sidebar} {
    display: none;
    grid-area: sidebar;
    width: var(--sidebar-width);

    ${mediaQuery(
      "greaterThanPhone",
      css`
        display: initial;
      `,
    )}
  }
  ${Footer} {
    grid-area: footer;
  }
`;

interface PageProps extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  sidebar?: ReactNode;
  layoutMode?: LayoutMode;
}
const PageLayout: FC<PageProps> = ({
  children,
  header,
  footer,
  sidebar,
  layoutMode = "default",
  ...restProps
}: PageProps) => {
  return (
    <ScrollHandler>
      <Container {...restProps} $layoutMode={layoutMode}>
        {header}
        {sidebar}
        {children}
        {footer}
      </Container>
    </ScrollHandler>
  );
};

export { Header, Sidebar, Body, Footer };

export default PageLayout;
