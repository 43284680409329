import { Trans } from "@lingui/macro";
import { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../constants";
import LinkToTop from "../../../lib/LinkToTop";
import { Text } from "../../../lib/Text";
import Title from "../../../lib/Title";
import mediaQuery from "../../../utils/mediaQuery";
import CategoryBadge from "../CategoryBadge";
import { Article } from "../models/Article";

const Container = styled.div`
  background-color: ${colors.white};
  padding: 20px;

  ${mediaQuery(
    "desktopMedium",
    css`
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      padding: 0;
      width: 100%;
    `,
  )}
`;

const ImageContainer = styled.div`
  background-color: #e5e5e5;
  height: calc((100vw - 80px) / 295 * 180);
  margin-bottom: 20px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      height: auto;
      min-height: 250px;
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      margin-bottom: 0;
      min-height: 310px;
      width: 310px;
    `,
  )};
`;

const Image = styled.img`
  aspect-ratio: 36 / 31;
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

const Info = styled.div`
  background-color: ${colors.white};
  box-sizing: border-box;

  ${mediaQuery(
    "desktopMedium",
    css`
      flex: 1;
      padding: 30px 40px;
    `,
  )}
`;

const Intro = styled(Text)`
  margin-bottom: 20px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin-bottom: 30px;
    `,
  )}
`;

const StyledTitle = styled(Title)`
  margin-bottom: 10px;
`;

const ReadMore = styled(LinkToTop)`
  color: ${colors.blue};
`;

const CategoryBadgeContainerMobile = styled.div`
  margin-bottom: 20px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      display: none;
    `,
  )}
`;

const CategoryBadgeContainerDesktop = styled.div`
  margin-bottom: 10px;
  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const ItemLarge: FC<Article> = ({
  category,
  imageSet,
  intro,
  name,
  url,
}: Article) => (
  <Container>
    <CategoryBadgeContainerMobile>
      <CategoryBadge {...category} />
    </CategoryBadgeContainerMobile>
    <LinkToTop css="display: contents" to={url}>
      <ImageContainer>
        <Image
          alt={name}
          height={`${imageSet.aspect_ratio.split(":")[1]}`}
          sizes="(max-width: 768px) 100vw, 310px"
          src={imageSet.w512}
          srcSet={`${imageSet.w128} 128w, ${imageSet.w256} 256w, ${imageSet.w512} 512w, ${imageSet.w800} 800w`}
          width={`${imageSet.aspect_ratio.split(":")[0]}`}
        />
      </ImageContainer>
    </LinkToTop>
    <Info>
      <CategoryBadgeContainerDesktop>
        <CategoryBadge {...category} />
      </CategoryBadgeContainerDesktop>
      <LinkToTop css="text-decoration: none" to={url}>
        <StyledTitle as="h3" styleLevel="h2">
          {name}
        </StyledTitle>
      </LinkToTop>
      <Intro as="div" textType="body">
        {intro}
      </Intro>
      <ReadMore aria-hidden="true" to={url}>
        <Trans>Read more</Trans>
      </ReadMore>
    </Info>
  </Container>
);

export default ItemLarge;
