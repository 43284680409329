import { t } from "@lingui/macro";
import { useGetArticlesPopular } from "../hooks/useGetArticles";
import Items from "../Items";

const PopularBlock = () => {
  const { data, isLoading } = useGetArticlesPopular();

  return data?.length ? (
    <Items
      data={data}
      isLoading={isLoading}
      title={t`Popular Articles`}
    />
  ) : null;
};

export default PopularBlock;
