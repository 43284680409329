import { t } from "@lingui/macro";
import React, {
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useMessagesApi } from "../../../lib/messages";
import { useStoreCustomerSpecificCode } from "../ActivateMembershipScreen/hooks";
import Layout from "../Layout";
import imgSrc from "../Layout/signin-bg.jpeg";
import CheckEmailForm from "./CheckEmailForm";
import { Strategy } from "./CheckEmailForm/hooks";
import PasswordForm from "./PasswordForm";
import TwoFactorCodeForm from "./TwoFactorCodeForm";

const SignInScreen: React.FC = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const [verificationToken, setVerificationToken] =
    useState<string>();
  useStoreCustomerSpecificCode();
  const {
    fromRecovery = false,
    email = "",
    invalidTokenError = undefined,
    strategies,
  } = (location.state as
    | {
        fromRecovery?: boolean;
        email?: string;
        invalidTokenError?: string;
        strategies?: Strategy[];
      }
    | undefined) || {};
  const strategiesSafe = useMemo((): Strategy[] => {
    return strategies || [];
  }, [strategies]);

  const navigate = useNavigate();
  const onSetEmail = useCallback(
    (emailV: string, strategiesArg: Strategy[]) => {
      navigate("", {
        state: {
          email: emailV,
          strategies: strategiesArg,
        },
      });
    },
    [navigate],
  );
  const onChangeEmail = useCallback(() => {
    setVerificationToken(undefined);
    navigate("", {
      state: {
        email: "",
      },
    });
  }, [navigate]);

  const onCheckCode = useCallback((token: string) => {
    setVerificationToken(token);
  }, []);
  const messagesApi = useMessagesApi();
  const onSignedIn = useCallback(() => {
    setVerificationToken(undefined);
    queryClient
      .refetchQueries(["cookie"])
      .then(() => {
        messagesApi.success({
          content: t`Signed in successfully.`,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  }, [messagesApi, queryClient]);

  let screen: ReactNode;
  if (!email || fromRecovery) {
    screen = (
      <CheckEmailForm
        errorMessage={invalidTokenError}
        fromRecovery={fromRecovery}
        initialEmail={email}
        onSuccess={onSetEmail}
      />
    );
  } else if (verificationToken) {
    screen = (
      <TwoFactorCodeForm
        email={email}
        onChangeEmail={onChangeEmail}
        onSignedIn={onSignedIn}
        verificationToken={verificationToken}
      />
    );
  } else {
    screen = (
      <PasswordForm
        email={email}
        onChangeEmail={onChangeEmail}
        onCheckCode={onCheckCode}
        onSignedIn={onSignedIn}
        strategies={strategiesSafe}
      />
    );
  }

  return <Layout imgSrc={imgSrc}>{screen}</Layout>;
};

export default SignInScreen;
