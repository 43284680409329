import { Trans } from "@lingui/macro";
import React, { FC } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import { Title } from "../../lib";
import Link from "../../lib/Link";
import { Logo } from "../../lib/Logo";
import { Text } from "../../lib/Text";
import image from "./image.png";

const LogoLink = styled(Link)`
  display: block;
`;
const StyledLogo = styled(Logo)``;
const Header = styled.header``;

const NameOfError = styled(Title)`
  font-size: 24px;
  line-height: 100%;
`;
const CodeOfError = styled.div`
  color: ${colors.brownLightGrey1};
  font-family: ${fonts.alt};
  font-size: 24px;
  font-weight: bold;
  line-height: 100%;
`;

const HomeLink = styled(Link)``;
const IMG_HEIGHT_PX = 522;
const IMG_TOP_PX = 28;
const CARD_HEIGHT_PX = 365;
const Card = styled.main`
  align-items: center;
  border: 1px solid ${colors.brownLightGrey1};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: ${CARD_HEIGHT_PX}px;
  justify-content: center;
  margin-bottom: ${IMG_HEIGHT_PX - CARD_HEIGHT_PX + IMG_TOP_PX}px;
  position: relative;
  width: 800px;

  ${CodeOfError} {
    margin-bottom: 10px;
  }
  ${NameOfError} {
    margin-bottom: 14px;
  }

  ${HomeLink} {
    margin-top: 30px;
  }

  &:after {
    background-image: url(${image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: ${IMG_HEIGHT_PX}px;
    left: 465px;
    position: absolute;
    top: ${IMG_TOP_PX}px;
    width: 540px;
  }
`;

const Description = styled(Text).attrs({ as: "p" })`
  text-align: center;
`;

const PageLayout = styled.div`
  align-items: center;
  background-color: ${colors.white};
  display: grid;
  grid-template-areas: "page";
  min-height: 100%;
  position: relative;
  ${Header} {
    align-self: start;
    grid-area: page;
    justify-self: start;
    ${LogoLink} {
      padding-left: 30px;
      padding-top: 26px;
    }
  }

  ${Card} {
    align-self: center;
    grid-area: page;
    justify-self: center;
  }
`;

const Error404Screen: FC = () => {
  return (
    <PageLayout>
      <Header>
        <LogoLink to="/">
          <StyledLogo />
        </LogoLink>
      </Header>

      <Card>
        <CodeOfError>404</CodeOfError>
        <NameOfError>
          <Trans>The page you were looking for doesn’t exist</Trans>
        </NameOfError>
        <Description>
          <Trans>
            You may have mistyped the address <br />
            or the page may have moved
          </Trans>
        </Description>
        <HomeLink to="/">
          <Trans>Return home</Trans>
        </HomeLink>
      </Card>
    </PageLayout>
  );
};

export default Error404Screen;
