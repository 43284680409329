import React from "react";
import styled, { css } from "styled-components";
import LinkToTop from "../../../lib/LinkToTop";
import { Text } from "../../../lib/Text";
import mediaQuery from "../../../utils/mediaQuery";
import CategoryBadge from "../CategoryBadge";
import { Article } from "../models/Article";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  ${mediaQuery(
    "desktopMedium",
    css`
      flex: 0;
      width: 254px;
    `,
  )}
`;

const ImageContainer = styled.div`
  background-color: #e5e5e5;
  height: 150px;
  ${mediaQuery(
    "desktopMedium",
    css`
      height: 180px;
    `,
  )}
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

const Info = styled.div`
  background-color: #fff;
  flex: 1;
  padding: 16px;
`;

const CategoryBadgeContainer = styled.div`
  margin-bottom: 12px;
`;

const Item: React.FC<Article> = ({
  category,
  imageSet,
  name,
  url,
}: Article) => (
  <Container>
    <LinkToTop to={url}>
      <ImageContainer>
        <Image
          alt={name}
          height={`${imageSet.aspect_ratio.split(":")[1]}`}
          sizes="(max-width: 1200px) 210px, 254px"
          src={imageSet.w256}
          srcSet={`${imageSet.w128} 128w, ${imageSet.w256} 256w, ${imageSet.w512} 512w, ${imageSet.w800} 800w`}
          width={`${imageSet.aspect_ratio.split(":")[0]}`}
        />
      </ImageContainer>
    </LinkToTop>
    <Info>
      <CategoryBadgeContainer>
        <CategoryBadge {...category} />
      </CategoryBadgeContainer>
      <LinkToTop css="text-decoration: none" to={url}>
        <Text as="h3" textType="bodyMedium">
          {name}
        </Text>
      </LinkToTop>
    </Info>
  </Container>
);

export default Item;
