import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import reporter from "io-ts-reporters";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { ISODate } from "../../../io-ts/types";
import { wrongResponseFormat } from "../../../locales/errors";
import { useAxios } from "../../../network";

import CareNavigatorC, {
  CareNavigator,
} from "../../../network/jsonApiV2/models/specific/CareNavigator";
import CompanyC, {
  CompanyPlan,
  CompanyPlanC,
} from "../../../network/jsonApiV2/models/specific/Company";
import ProfileC, {
  ProfileEntityWithRelations,
  UpdateProfileEntity,
  UpdatePasswordEntity,
} from "../../../network/jsonApiV2/models/specific/Profile";

const ProfileDataC = t.intersection([
  ProfileC,
  t.type({
    relationships: t.type(
      {
        care_navigator: t.type({
          data: t.type({
            id: t.string,
            type: t.literal("call_expert"),
          }),
        }),
        company: t.type({
          data: t.type({
            id: t.string,
            type: t.literal("company"),
          }),
        }),
        company_plan: t.type({
          data: t.union([
            t.type({
              id: t.string,
              type: t.literal("company_plan"),
            }),
            t.null,
          ]),
        }),
      },
      "profileRelationships",
    ),
  }),
]);

const CompanyDataC = t.intersection([
  CompanyC,
  t.type({
    relationships: t.type(
      {
        company_plans: t.type({
          data: t.array(
            t.type({
              id: t.string,
              type: t.literal("company_plan"),
            }),
          ),
        }),
      },
      "companyRelationships",
    ),
  }),
]);

export type CompanyData = t.TypeOf<typeof CompanyDataC>;

export const responseC = t.type({
  data: ProfileDataC,
  included: t.array(
    t.union([CompanyDataC, CareNavigatorC, CompanyPlanC]),
  ),
  // meta:
});

export function responseToEntities(
  response: t.TypeOf<typeof responseC>,
): ProfileEntityWithRelations {
  const includedMap = {
    care_navigators: new Map(
      response.included
        .filter(
          (element): element is CareNavigator =>
            element.type === "call_expert",
        )
        .map((element) => [element.id, element]),
    ),
    companies: new Map(
      response.included
        .filter(
          (element): element is CompanyData =>
            element.type === "company",
        )
        .map((element) => [element.id, element]),
    ),
    companyPlans: new Map(
      response.included
        .filter(
          (element): element is CompanyPlan =>
            element.type === "company_plan",
        )
        .map((element) => [element.id, element]),
    ),
  };

  const { id, type, attributes, relationships } = response.data;

  const company = includedMap.companies.get(
    relationships.company.data.id,
  );

  const careNavigator = includedMap.care_navigators.get(
    relationships.care_navigator.data.id,
  );

  const companyPlan = relationships.company_plan.data
    ? includedMap.companyPlans.get(relationships.company_plan.data.id)
    : undefined;

  if (!company) {
    throw new Error("Company relationship not found in `included`");
  }

  const companyPlans = company.relationships.company_plans.data.map(
    (item) => {
      const plan = includedMap.companyPlans.get(item.id);
      if (!plan)
        throw new Error(
          "Company plan relationship not found in `included`",
        );
      return plan;
    },
  );

  if (!careNavigator) {
    throw new Error(
      "Care Navigator relationship not found in `included`",
    );
  }

  return {
    attributes,
    id,
    relationships: {
      care_navigator: careNavigator,
      company: {
        ...company,
        relationships: {
          companyPlans,
        },
      },
      companyPlan,
    },
    type,
  };
}

export function useProfile(): UseQueryResult<ProfileEntityWithRelations> {
  const axios = useAxios();

  return useQuery("profile", async () => {
    const response = await axios.get(`/v2/profile`);

    const decodeResult = responseC.decode(response.data);
    if (isLeft(decodeResult)) {
      // eslint-disable-next-line no-console
      console.error(reporter.report(decodeResult));
      throw wrongResponseFormat;
    }

    return responseToEntities(decodeResult.right);
  });
}

export function useUpdateProfile() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdateProfileEntity): Promise<void> => {
      await axios.put<unknown>(`/v2/profile`, {
        ...payload,
        dob: payload.dob ? ISODate.encode(payload.dob) : undefined,
      });
    },
    {
      onSuccess: () => {
        return (
          queryClient
            .refetchQueries(["profile"])
            // eslint-disable-next-line no-console
            .catch((err) => console.error(err))
        );
      },
    },
  );
}

export function useUpdatePassword() {
  const axios = useAxios();
  return useMutation(
    async (payload: UpdatePasswordEntity): Promise<void> => {
      await axios.put<unknown>(
        `/v2/profile/change_password`,
        payload,
      );
    },
    {
      onSuccess: (status) => {
        // eslint-disable-next-line no-console
        console.log(status);
      },
    },
  );
}
