import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import * as yup from "yup";
import ReCAPTCHA from "../../../components/ReCAPTCHA";
import { colors } from "../../../constants";
import Alert from "../../../lib/Alert";
import FormField, { Description } from "../../../lib/forms/FormField";
import Input from "../../../lib/forms/primitives/Input";
import { useHandleSubmitImpl } from "../../../utils/forms";
import mediaQuery from "../../../utils/mediaQuery";
import { passwordRegexp } from "../../../utils/regexp";
import renderError from "../../../utils/renderError";
import Layout from "../Layout";
import imgSrc from "../Layout/signin-bg2-a73bb9ef0fc46cb003538bafd272607d.jpeg";
import { Header, StyledButton } from "../styles";
import {
  useCheckRestorePasswordToken,
  useResetPassword,
} from "./hooks";

const StyledForm = styled.form`
  ${StyledButton} {
    margin-top: 0;
  }
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${mediaQuery(
    "desktopMedium",
    css`
      max-width: 355px;
    `,
  )}

  .labelText {
    margin-bottom: 12px;
  }
`;

const StyledLayout = styled(Layout)`
  ${Header} {
    display: block;
  }
  ${StyledForm} {
    margin-top: 30px;
    ${mediaQuery(
      "desktopMedium",
      css`
        margin-top: 40px;
      `,
    )}
  }

  ${Description} {
    color: #909090;
    font-size: 13px;
    line-height: 16px;
  }
`;

const getFormSchema = (minPasswordLength: number) => {
  return yup.object().shape({
    password: yup
      .string()
      .required(t`Password required`)
      .min(
        minPasswordLength,
        t`Password must be at least ${minPasswordLength} characters long`,
      )
      .matches(
        passwordRegexp,
        t`Must have 8+ characters in length and contain lower and upper case letters and at least one number (0-9)`,
      ),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], t`doesn't match Password`),
  });
};

export interface FormProps {
  password: string;
  passwordConfirmation: string;
}
const ChangePasswordScreen: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get("reset_password_token");
  const handleInvalidTokenError = useCallback(
    (error?: unknown) => {
      navigate("/signin", {
        state: {
          invalidTokenError: error
            ? renderError(error)
            : t`The reset link you're trying to use can't be found or expired. Please start again.`,
        },
      });
    },
    [navigate],
  );

  useEffect(() => {
    // TODO: use error boundary to redirect (throw an Error and do not render the rest of the form)
    if (!resetPasswordToken) {
      handleInvalidTokenError();
    }
  }, [handleInvalidTokenError, resetPasswordToken]);
  const {
    data: checkResetTokenData,
    isLoading: checkResetTokenIsLoading,
    error: checkResetTokenError,
  } = useCheckRestorePasswordToken(resetPasswordToken || "");
  useEffect(() => {
    if (checkResetTokenError) {
      handleInvalidTokenError(checkResetTokenError);
    }
  }, [checkResetTokenError, handleInvalidTokenError]);

  const minPasswordLength =
    checkResetTokenData?.passwordMinLength || 8;
  const resolver = useMemo(
    () => yupResolver(getFormSchema(minPasswordLength)),
    [minPasswordLength],
  );
  const {
    isLoading,
    error: externalError,
    mutate: resetPassword,
  } = useResetPassword();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },

    resolver,
  });
  const recaptchaRef = useRef<ReCAPTCHABase>(null);
  const onSubmit = useCallback(
    ({
      password,
      passwordConfirmation,
      recaptchaToken,
    }: {
      password: string;
      passwordConfirmation: string;
      recaptchaToken: string;
    }) => {
      if (resetPasswordToken) {
        resetPassword(
          {
            password,
            passwordConfirmation,
            recaptchaToken,
            resetPasswordToken,
          },
          {
            onSuccess: () => {
              // TODO: message: Your password has been changed successfully. You are now signed in.
              navigate("/");
            },
          },
        );
      } else {
        // eslint-disable-next-line no-console
        console.error("Reset password token is not set");
      }
    },
    [navigate, resetPassword, resetPasswordToken],
  );
  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);
  const error = externalError || internalError;

  if (checkResetTokenIsLoading) {
    // TODO: render loader; the best option is ReactV18 and Suspense
    return <>Loading...</>;
  }

  return (
    <StyledLayout imgSrc={imgSrc}>
      {!!error && <Alert status="error">{renderError(error)}</Alert>}
      <>
        <Header>
          <Trans>Change password</Trans>
        </Header>
        <StyledForm onSubmit={handleSubmitImpl}>
          <FormField
            description={
              <Trans>
                Must have {minPasswordLength}+ characters in length
                and contain lower and upper case letters and at least
                one number (0-9)
              </Trans>
            }
            error={errors.password?.message}
            label={t`Create a password`}
            labelTextClassName="labelText"
          >
            <Input
              {...register("password")}
              $activeColor={colors.blueGreen}
              autoComplete="new-password"
              minLength={minPasswordLength}
              type="password"
            />
          </FormField>
          <FormField
            error={errors.passwordConfirmation?.message}
            label={t`Confirm password`}
            labelTextClassName="labelText"
          >
            <Input
              {...register("passwordConfirmation")}
              $activeColor={colors.blueGreen}
              autoComplete="new-password"
              type="password"
            />
          </FormField>
          <StyledButton
            css="white-space: nowrap;"
            disabled={isLoading}
            kind="filledBlueGreen"
            type="submit"
          >
            <Trans>Change password</Trans>
          </StyledButton>
        </StyledForm>
      </>
      <ReCAPTCHA ref={recaptchaRef} />
    </StyledLayout>
  );
};

export default ChangePasswordScreen;
