import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ButtonWithLoader } from "../../lib/Button/ButtonWithLoader";
import FormField from "../../lib/forms/FormField";
import Input from "../../lib/forms/primitives/Input";
import Radio from "../../lib/forms/primitives/Radio";
import { GreyBlockTitle, IconText } from "../../lib/GreyBlock";
import { useMessagesApi } from "../../lib/messages";
import { ProfileEntityWithRelations } from "../../network/jsonApiV2/models/specific/Profile";
import { useHandleSubmitImpl } from "../../utils/forms";
import { useUpdateProfile } from "../ProfilePage/hooks";
import {
  Bottom,
  SubmitContainer,
  GreyBlockCustom,
  Description,
  FieldSets,
  FormTitle,
  MemberIdTextContainer,
  RadioFormField,
} from "./Form.style";
import { MedicalCardPic } from "./MedicalCardPic";

type Option = {
  value: string;
  label: string;
};

export type Props = {
  profileData: ProfileEntityWithRelations;
  medicalPlanOptions: Option[];
  medicalPlanTypeOptions: Option[];
  companyName: string;
};

interface FormProps {
  companyPlanId: string;
  companyPlanType: string;
  memberId: string;
}

const getResolver = () => {
  const FormSchema = yup.object().shape({
    companyPlanId: yup.string().required(() => t`Please select`),
    companyPlanType: yup.string().required(() => t`Please select`),
    memberId: yup.string(),
  });

  return yupResolver(FormSchema);
};
const FormAddressPaymentId: FC<Props> = ({
  profileData,
  medicalPlanOptions,
  medicalPlanTypeOptions,
  companyName,
}: Props) => {
  const navigate = useNavigate();
  const messagesApi = useMessagesApi();
  const resolver = useMemo(() => getResolver(), []);
  const { mutate: updateProfile, isLoading } = useUpdateProfile();

  const defaultValues = {
    companyPlanId: profileData.relationships.companyPlan?.id || "",
    companyPlanType:
      profileData.attributes.company_plan_type?.value || "",
    memberId: profileData.attributes.member_id || "",
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues,
    resolver,
  });

  const onSubmit = useCallback(
    ({ companyPlanId, companyPlanType, memberId }: FormProps) => {
      updateProfile(
        {
          company_plan_id: companyPlanId || "",
          company_plan_type: companyPlanType || "",
          member_id: memberId,
        },
        {
          onError: (e) => {
            // eslint-disable-next-line no-console
            console.log(e);
            messagesApi.error({
              content: t`Error occurred while updating profile`,
            });
          },
          onSuccess: () => {
            messagesApi.success({ content: t`Profile updated` });
            navigate("/notifications");
          },
        },
      );
    },
    [updateProfile, messagesApi, navigate],
  );

  const { handler: handleSubmitImpl } = useHandleSubmitImpl(
    handleSubmit,
    onSubmit,
  );

  return (
    <form onSubmit={handleSubmitImpl}>
      <FormTitle>
        <Trans>Complete your profile</Trans>
      </FormTitle>
      <FieldSets>
        <GreyBlockCustom overhangs="30">
          <GreyBlockTitle
            icon={<IconText>1</IconText>}
            title={t`Your Medical Plan`}
          />
          <div
            css={`
              display: flex;
              flex-direction: column;
              gap: 20px;
            `}
          >
            <RadioFormField
              error={errors.companyPlanId?.message}
              formGroup
              label={t`Select your Medical Plan sponsored by employer`}
              layout="mixed"
            >
              {medicalPlanOptions.map(({ label, value }) => (
                <Radio
                  {...register("companyPlanId")}
                  key={value}
                  bordered
                  label={label}
                  value={value}
                />
              ))}
            </RadioFormField>

            <RadioFormField
              error={errors.companyPlanType?.message}
              formGroup
              label={t`Select type`}
              layout="mixed"
            >
              {medicalPlanTypeOptions.map(({ label, value }) => (
                <Radio
                  {...register("companyPlanType")}
                  key={value}
                  bordered
                  label={label}
                  value={value}
                />
              ))}
            </RadioFormField>
          </div>
        </GreyBlockCustom>

        {profileData.attributes.ask_member_id && (
          <GreyBlockCustom overhangs="30">
            <GreyBlockTitle
              icon={<IconText>2</IconText>}
              title={t`Member ID`}
            />
            <MemberIdTextContainer>
              <Description>
                {profileData.attributes.notice_member_id_change ? (
                  <Trans>
                    If you’re enrolled in the new medical plan with
                    JAMF, please type in your Member ID from your BCBS
                    medical card.
                  </Trans>
                ) : (
                  <Trans>
                    If you&apos;re enrolled in a medical plan with{" "}
                    {companyName}, please type in your Member ID from
                    your medical card.
                  </Trans>
                )}
              </Description>
              <FormField
                error={errors.memberId?.message}
                label={t`Member ID`}
              >
                <Input
                  {...register("memberId")}
                  aria-invalid={!!errors.memberId}
                  autoComplete="memberId"
                  type="text"
                />
              </FormField>
            </MemberIdTextContainer>
            <MedicalCardPic />
          </GreyBlockCustom>
        )}
      </FieldSets>
      <Bottom>
        <SubmitContainer>
          <ButtonWithLoader
            disabled={isLoading}
            isLoading={isLoading}
            kind="filledCoral"
            type="submit"
          >
            <Trans>Submit</Trans>
          </ButtonWithLoader>
        </SubmitContainer>
      </Bottom>
    </form>
  );
};

export default FormAddressPaymentId;
