import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { t, Trans } from "@lingui/macro";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import * as yup from "yup";
import { colors } from "../../../constants";
import Button from "../../../lib/Button";
import FormField from "../../../lib/forms/FormField";
import Input from "../../../lib/forms/primitives/Input";
import Skeleton from "../../../lib/Skeleton";
import Title from "../../../lib/Title";
import { JSONApiErrorsException } from "../../../network/jsonApi/core/JSONApiErrorsPayload";
import { mapJSONApiErrors } from "../../../utils/errors";
import { useHandleSubmitImpl } from "../../../utils/forms";
import mediaQuery from "../../../utils/mediaQuery";
import ScrollHandler from "../../ScrollHandler";
import {
  useInvitePartner,
  usePartner,
  usePartnerInvitationEnabled,
  useRevokePartner,
} from "./hooks";

const getResolver = () => {
  const FormSchema = yup.object().shape({
    email: yup
      .string()
      .label("email")
      .trim()
      .required(t`Email is required`)
      .email(t`Email must be a valid email address`),
  });

  return yupResolver(FormSchema);
};

interface FormProps {
  email: string;
}

const StyledFormField = styled(FormField)`
  width: 100%;

  [role="alert"] {
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translateY(calc(100% + 4px));
  }

  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: 334px;
    `,
  )}
`;
const StyledForm = styled.form`
  align-items: flex-end;
  column-gap: 20px;
  display: flex;
  flex-wrap: wrap;

  row-gap: 20px;

  ${StyledFormField} {
    margin-top: 40px;
  }

  ${mediaQuery(
    "desktopMedium",
    css`
      flex-wrap: nowrap;

      ${StyledFormField} {
        margin-top: 50px;
      }
    `,
  )}
`;

const Container = styled.div`
  max-width: var(--content-max-width);
  padding: 24px var(--content-margin-side) 0;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-top: 50px;
    `,
  )}
`;

const Content = styled.div`
  margin-top: 20px;
  max-width: 650px;

  p:nth-child(1) {
    line-height: 24px;
    margin: 0;
  }
`;
const SuccessMessage = styled.p`
  color: ${colors.green};
  font-weight: bold;
  margin: 50px 0 30px;
`;

const AddPartner: FC = () => {
  const partnerInvitationEnabled = usePartnerInvitationEnabled();
  const navigate = useNavigate();
  useEffect(() => {
    if (!partnerInvitationEnabled) {
      // not allowed to see the screen
      navigate("/profile");
    }
  }, [navigate, partnerInvitationEnabled]);
  const { data, isLoading: isDataLoading } = usePartner();

  const resolver = useMemo(() => getResolver(), []);
  const { mutate: invitePartner, isLoading } = useInvitePartner();
  const { mutate: revokePartner } = useRevokePartner();

  const {
    setError,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    resolver,
  });

  const onSubmit = useCallback(
    ({ email }: FormProps) => {
      invitePartner(
        { email },
        {
          onError: (err) => {
            if (err instanceof JSONApiErrorsException) {
              const parsedErrors = mapJSONApiErrors(err.errors, {
                email: "data/attributes/email",
              });

              for (const entry of Object.entries(parsedErrors)) {
                const error = entry[1];
                const key = entry[0] as keyof typeof parsedErrors;
                if (error?.title) {
                  setError(key, {
                    message: error.title,
                  });
                }
              }
            }
          },
        },
      );
    },
    [invitePartner, setError],
  );

  const revokeAccessHandler = () => {
    revokePartner();
  };

  const { handler: handleSubmitImpl } = useHandleSubmitImpl(
    handleSubmit,
    onSubmit,
  );

  return (
    <ScrollHandler>
      <Container>
        <Title level="h2">
          <Trans>Add partner</Trans>
        </Title>
        <Content>
          <p>
            <Trans>
              Every employee regardless of his or her age, race,
              sexual orientation, marital status, and fertility health
              condition is eligible for the benefit. Adult dependents,
              such as spouses and civil partners, are also eligible to
              utilize this benefit subject to the employee’s lifetime
              maximum. If you would like to share access to the
              benefits with your loved one, please submit below.
            </Trans>
          </p>

          <Skeleton
            active
            css={`
              margin-top: 20px;
            `}
            loading={isDataLoading}
          >
            {data?.attributes.email ? (
              <>
                <SuccessMessage>
                  <Trans>
                    You&lsquo;ve invited {data.attributes.email} to
                    your account.
                  </Trans>
                </SuccessMessage>

                <Button
                  kind="filledCoral"
                  onClick={revokeAccessHandler}
                  resetWidth
                >
                  <Trans>Revoke access</Trans>
                </Button>
              </>
            ) : (
              <StyledForm onSubmit={handleSubmitImpl}>
                <StyledFormField
                  error={errors.email?.message}
                  hideLabel
                  label={t`Email`}
                >
                  <Input
                    {...register("email")}
                    autoComplete="email"
                    placeholder={t`Email`}
                    type="text"
                  />
                </StyledFormField>

                <Button
                  disabled={isLoading}
                  kind="filledCoral"
                  resetWidth
                  type="submit"
                >
                  <Trans>Send invitation</Trans>
                </Button>
              </StyledForm>
            )}
          </Skeleton>
        </Content>
      </Container>
    </ScrollHandler>
  );
};

export default AddPartner;
