import type { MessageDescriptor } from "@lingui/core";
import { defineMessage } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC, ImgHTMLAttributes, ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../constants";
import pregnant from "./pics/expecting_a_baby.png";
import gender_affirming_care from "./pics/gender_affirming_care.png";
import newborn from "./pics/having_a_newborn.png";
import menopause from "./pics/menopause.png";
import mens_optimization from "./pics/mens_optimization.png";
import planningLater from "./pics/planning_later.png";
import planningNow from "./pics/planning_now.png";
import parent from "./pics/working_parent.png";
import { ProfileStatus } from "./types";

export const iconMap: Record<
  ProfileStatus,
  {
    icon: string;
    title: MessageDescriptor;
  }
> = {
  expecting_a_baby: {
    icon: pregnant,
    title: defineMessage({ message: "Pregnant" }),
  },
  gender_affirming_care: {
    icon: gender_affirming_care,
    title: defineMessage({ message: "Gender affirming care" }),
  },
  having_a_newborn: {
    icon: newborn,
    title: defineMessage({ message: "Having a newborn" }),
  },
  menopause: {
    icon: menopause,
    title: defineMessage({ message: "Menopause" }),
  },
  mens_optimization: {
    icon: mens_optimization,
    title: defineMessage({ message: "Men’s optimization" }),
  },
  planning_later: {
    icon: planningLater,
    title: defineMessage({ message: "Want a child later" }),
  },
  planning_now: {
    icon: planningNow,
    title: defineMessage({ message: "Want a child now" }),
  },
  working_parent: {
    icon: parent,
    title: defineMessage({ message: "Working parent" }),
  },
};

const SimpleIcon = styled.img`
  background: transparent;
  border-radius: 50px;
`;

export const FamilyStatusIcon: FC<
  Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> & {
    status: ProfileStatus;
  }
> = ({ status, ...restProps }) => {
  return (
    <SimpleIcon {...restProps} alt="" src={iconMap[status].icon} />
  );
};

const Icon = styled.img`
  height: 80px;
  width: 80px;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: ${fonts.main}, sans-serif;
  font-size: 15px;
  line-height: 24px;
  height: 48px;
  max-width: 100px;
`;

const Button = styled.button`
  background: white;
  border: none;
  color: black;

  position: relative;
  text-align: center;
  border-radius: 10px;
  background: rgba(245, 243, 241, 0.5);
  height: 170px;
  box-sizing: border-box;
  padding: 16px 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &[disabled] {
    cursor: not-allowed;
    filter: grayscale(80%);
  }
  :not([disabled]) {
    cursor: pointer;

    &:hover,
    &:focus {
      background: #f5f3f1;
    }
    &[data-active] {
      /* background: rgba(245, 243, 241, 0.50); */
      box-shadow: inset 0 0 0 2px ${colors.blue};
      color: ${colors.blue};
      font-weight: 500;
    }
  }
`;

export interface FamilyStatusProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  status: ProfileStatus;
  isActive?: boolean;
}
const FamilyStatus: FC<FamilyStatusProps> = ({
  isActive = false,
  status,
  children,
  ...restProps
}) => {
  const { i18n } = useLingui();
  return (
    <Button {...restProps} data-active={isActive || undefined}>
      <Icon alt="" src={iconMap[status].icon} />
      <Title>{i18n._(iconMap[status].title)}</Title>
    </Button>
  );
};

export default FamilyStatus;
