/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, forwardRef, InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../constants";
import { useFormFieldContext } from "../../FormField/context";

export interface RadioProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
  children?: undefined;
  label: string;
  bordered?: boolean;
}

export const Circle = styled.span``;
export const Input = styled.input``;

const Border = styled.div`
  border: 2px solid currentColor;
  border-radius: 5px;
  inset: 0;
  padding-block: 0;
  padding-inline: 15px 18px;
  position: absolute;
`;

// TODO: onHover styles
export const Label = styled.label<{
  $bordered: boolean;
  $hasError: boolean;
}>`
  align-items: center;
  color: ${colors.black};
  cursor: pointer;
  display: inline-flex;
  font-family: ${fonts.main};
  font-size: 16px;
  line-height: 24px;
  position: relative;
  user-select: none;

  ${Input} {
    box-sizing: border-box;
    cursor: pointer;
    inset: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
  }

  ${Circle} {
    align-items: center;
    color: ${colors.brownBlack};
    display: inline-flex;
    justify-content: center;
    margin-inline-end: 8px;
    &::before {
      border: 1px solid currentColor;
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      cursor: pointer;
      height: 18px;
      position: relative;
      width: 18px;
    }
  }

  ${Input}:checked+${Circle} {
    &::after {
      background: currentColor;
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      cursor: pointer;
      height: 12px;
      position: absolute;
      width: 12px;
    }
  }
  ${Input}:disabled+${Circle} {
    color: ${colors.brownLightGrey1};
  }

  ${({ $bordered }) =>
    $bordered
      ? css`
          height: 50px;

          padding-block: 0;
          padding-inline: 15px 18px;
          ${Border} {
            color: ${colors.brownLightGrey1};
          }
          ${Circle} {
            color: ${colors.brownLightGrey1};
          }

          ${Input}:checked+${Circle} {
            color: ${colors.coral};
          }
          ${Input}:checked+${Circle}+${Border} {
            color: ${colors.coral};
          }
        `
      : undefined}

  ${({ $hasError }) =>
    $hasError
      ? css`
          ${Circle} {
            color: ${colors.red};
            &:before {
              border-width: 2px;
            }
          }
        `
      : undefined}
`;
const Radio: FC<RadioProps> = forwardRef<
  HTMLInputElement,
  RadioProps
>((props, ref) => {
  const {
    label,
    className,
    style,
    bordered = false,
    "aria-invalid": ariaInvalid,
    ...restProps
  } = props;

  const invalid = !!ariaInvalid && ariaInvalid !== "false";
  const formFieldContextValue = useFormFieldContext();

  const hasError = invalid || formFieldContextValue["aria-invalid"];

  return (
    <Label
      $bordered={bordered}
      $hasError={!!hasError}
      className={className}
      style={style}
    >
      <Input
        {...restProps}
        ref={ref}
        aria-invalid={
          ariaInvalid || formFieldContextValue["aria-invalid"]
        }
        type="radio"
      />
      <Circle />
      {bordered && <Border />}
      {label}
    </Label>
  );
});

export default Radio;
