import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../constants";
import LayoutClinic from "./LayoutClinic";
import LayoutDoula from "./LayoutDoula";
import LayoutProvider from "./LayoutProvider";
import LayoutRoom from "./LayoutRoom";
import { ClinicEntityWithRelations } from "./models/Clinic";

export interface Props {
  clinic: ClinicEntityWithRelations;
  clinicLayout: "clinic" | "doula" | "provider" | "room";
  programType: string;
}

const Container = styled.div`
  &:not(:empty) {
    background-color: ${colors.white};
    border: 1px solid ${colors.brownLightGrey1};
    border-radius: 10px;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 5%);
    color: ${colors.brownBlack};
    font-size: 14px;
    margin: 30px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Clinic: FC<Props> = ({
  clinic,
  clinicLayout,
  programType,
}: Props) => (
  <Container>
    {clinicLayout === "room" && <LayoutRoom {...clinic} />}
    {clinicLayout === "provider" && (
      <LayoutProvider {...clinic} programType={programType} />
    )}
    {clinicLayout === "clinic" && <LayoutClinic {...clinic} />}
    {clinicLayout === "doula" && <LayoutDoula {...clinic} />}
  </Container>
);

export default Clinic;
