import { t } from "@lingui/macro";
import { useGetArticlesMustRead } from "../hooks/useGetArticles";
import Items from "../Items";

const MustReadBlock = () => {
  const { data, isLoading } = useGetArticlesMustRead();

  return data?.length ? (
    <Items data={data} isLoading={isLoading} title={t`Must Read`} />
  ) : null;
};

export default MustReadBlock;
