/* eslint-disable max-lines */
import { t, Trans } from "@lingui/macro";
import Axios from "axios";
import { FC, useState, useCallback, useEffect } from "react";
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
} from "react-plaid-link";
import { useParams, useLocation } from "react-router";
import { colors } from "../../constants";
import Button from "../../lib/Button";
import { Logo } from "../../lib/Logo";
import { useMessagesApi } from "../../lib/messages";
import Icon from "../../lib/SharedIcon";
import Spinner from "../../lib/Spinner";
import { formatDate } from "../../utils/date-fns";
import { sendPlaidTokenToRN } from "../../utils/react-native";
import { usePayment, useMakePayment } from "./hooks";
import {
  Wrapper,
  Box,
  StatusBox,
  Text,
  Bold,
  Divider,
  Caption,
  Balance,
  Title,
  TitleAlt,
  NoteContainer,
  Note,
  NoteTitle,
  FailAction,
  Links,
  ExternalLink,
  LinkDivider,
  ResultLinks,
  ResultLink,
  CheckIconWrapper,
  CrossIconWrapper,
  BackButtonContainer,
  CrossButtonContainer,
  BackButton,
  CrossButton,
  DesktopBack,
  MobileBack,
  Loader,
  LogoContainer,
  SubmitButton,
} from "./style";

type ParamTypes = {
  uid: string;
};

const Payment: FC = () => {
  const isReactNative = !!window.ReactNativeWebView;
  // https://stackoverflow.com/questions/69992370/why-react-router-v6-useparams-returns-object-with-properties-possibly-undefined
  const { uid } = useParams<keyof ParamTypes>() as ParamTypes;
  const { search } = useLocation();
  const { data, isLoading } = usePayment(uid);
  const { mutate: makePayment } = useMakePayment();

  const [accountError, setAccountError] = useState(true);
  const [isFail, setIsFail] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const messagesApi = useMessagesApi();

  const makePaymentImplementation = useCallback(
    (publicToken: string, accountId: string) => {
      const payload = {
        accountId,
        publicToken,
        uid,
      };

      makePayment(payload, {
        onError: (err) => {
          if (Axios.isAxiosError(err)) {
            if (err.response?.status !== 422) setAccountError(false);
          }
          setIsFail(true);
        },
        onSettled: () => {
          setIsPending(false);
          localStorage.removeItem("link_token");
          localStorage.removeItem("redirect_bill_uid");
        },
      });
    },
    [makePayment, uid],
  );
  const onPlaidSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken, metadata) => {
      makePaymentImplementation(publicToken, metadata.accounts[0].id);
    },
    [makePaymentImplementation],
  );

  const eventsHandler = useCallback((eventName: string) => {
    if (eventName === "OPEN") {
      setIsPending(true);
    } else if (eventName === "EXIT") {
      localStorage.removeItem("link_token");
      localStorage.removeItem("redirect_bill_uid");
      setIsPending(false);
    } else if (eventName === "RN_ERROR") {
      setIsPending(false);
      setIsFail(true);
    }
  }, []);

  useEffect(() => {
    window.ReactNativePlaidSdkSuccess = (payload) => {
      const { publicToken } = payload;
      const accountId = payload.metadata.accounts[0].id;
      if (publicToken && accountId) {
        makePaymentImplementation(publicToken, accountId);
      }
    };
    window.ReactNativePlaidSdkOpen = () => {
      eventsHandler("OPEN");
    };
    window.ReactNativePlaidSdkExit = (payload) => {
      if (payload.error && payload.error.errorCode) {
        eventsHandler("RN_ERROR");
      } else {
        eventsHandler("EXIT");
      }
    };

    return () => {
      delete window.ReactNativePlaidSdkSuccess;
      delete window.ReactNativePlaidSdkOpen;
    };
  }, [eventsHandler, makePaymentImplementation]);

  const config: PlaidLinkOptions = {
    onEvent: eventsHandler,
    onSuccess: onPlaidSuccess,
    token: data?.attributes.plaid_link_token || null,
  };
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (
      search.includes("internal_oauth_error=true") ||
      data?.attributes.state === "failed"
    )
      setIsFail(true);
  }, [search, data?.attributes.state]);

  if (!data) {
    if (isLoading) {
      return <></>;
    }
    throw new Error(
      t`Sorry, something went wrong. Please reload the page or try again later.`,
    );
  }

  const callPlaidHandler = () => {
    if (!data.attributes.plaid_link_token) {
      messagesApi.error({
        content: t`Cannot process the payment at the moment. Please try again later`,
      });
    } else if (ready) {
      if (isReactNative) {
        // React Native version does not call web SDK, instead it delegates an SDK call to the native code passing required data
        sendPlaidTokenToRN(data.attributes.plaid_link_token);
      } else {
        // Normal web code, using plaid sdk and iframe
        // Set storage bill_uid and link_token if new payment initiation
        localStorage.setItem(
          "link_token",
          data.attributes.plaid_link_token,
        );
        localStorage.setItem("redirect_bill_uid", uid);
        open();
      }
    }
  };

  const resLinks = (
    <ResultLinks>
      <ResultLink to="/notifications">
        <Trans>Go to Notifications</Trans>
      </ResultLink>
      <ResultLink to="/">
        <Trans>Go to Home page</Trans>
      </ResultLink>
    </ResultLinks>
  );

  return (
    <Wrapper>
      <LogoContainer to="/">
        <Logo fill={colors.brownLightGrey1} />
      </LogoContainer>

      {/* Back to notifications link */}
      {data?.attributes.state === "requested" &&
        !isPending &&
        !isFail && (
          <BackButtonContainer>
            <BackButton to="/notifications">
              <DesktopBack>
                <Icon
                  css={`
                    [dir="rtl"] {
                      transform: rotate(180deg);
                    }
                  `}
                  type="linkBack"
                />{" "}
                <Trans>Back to Notifications</Trans>
              </DesktopBack>
              <MobileBack>
                <Icon
                  css={`
                    [dir="rtl"] {
                      transform: rotate(180deg);
                    }
                  `}
                  type="arrowLeft"
                />
              </MobileBack>
            </BackButton>
          </BackButtonContainer>
        )}

      {/* Mobile back to notifications link */}
      {(isFail || data?.attributes.state !== "requested") && (
        <CrossButtonContainer>
          <CrossButton to="/notifications">
            <Icon type="cross" />
          </CrossButton>
        </CrossButtonContainer>
      )}

      {/* Spinner for loading state */}
      {isPending && (
        <StatusBox>
          <Loader>
            <Spinner
              color={colors.brownLightGrey1}
              label={t`Loading`}
              size="22px"
            />
          </Loader>
        </StatusBox>
      )}

      {/* After payment success or payment is still initiated block */}
      {data?.attributes.state === "initiated" && (
        <StatusBox>
          <TitleAlt>
            <CheckIconWrapper>
              <Icon type="checkSlim" />
            </CheckIconWrapper>
            <Trans>Payment initiated</Trans>
          </TitleAlt>
          <Text>
            <Trans>
              Thank you! Funds will be withdrawn from your account in
              1-2 business day
              <br />
              After Stork Club receives the payment, we will send a
              confirmation notification.
            </Trans>
          </Text>
          {resLinks}
        </StatusBox>
      )}

      {/* Payment paid block */}
      {(data?.attributes.state === "paid" ||
        data?.attributes.state === "manually_collected") && (
        <StatusBox>
          <TitleAlt>
            <CheckIconWrapper>
              <Icon type="checkSlim" />
            </CheckIconWrapper>
            <Trans>Payment confirmed</Trans>
          </TitleAlt>
          <Text>
            <Trans>Thank you! The bill has been paid.</Trans>
          </Text>
          {resLinks}
        </StatusBox>
      )}

      {/* Payment failed block */}
      {isFail && (
        <StatusBox>
          <TitleAlt>
            <CrossIconWrapper>
              <Icon type="cross" />
            </CrossIconWrapper>
            <Trans>Something went wrong</Trans>
          </TitleAlt>
          <Text>
            {accountError ? (
              <span>
                <Trans>
                  There was an error processing your payment.
                  <br />
                  Please ensure that you have sufficient funds in your
                  account and try again.
                </Trans>
              </span>
            ) : (
              <span>
                <Trans>
                  There was an unexpected error. Please try again.
                </Trans>
              </span>
            )}
            <br />
            <Trans>
              If this error persists, please contact our{" "}
              <a href="mailto:support@joinstorkclub.com">
                support team
              </a>
            </Trans>
            .
          </Text>

          <FailAction>
            <Button
              kind="filledCoral"
              onClick={() => setIsFail(false)}
            >
              <Trans>Try again</Trans>
            </Button>
          </FailAction>

          {resLinks}
        </StatusBox>
      )}

      {/* Always visible payment info block */}
      <Box>
        <Text>
          <Trans>
            Bill # <Bold>{data?.attributes.sequential_id}</Bold>
          </Trans>
        </Text>
        <Text>
          <Trans>
            Service{" "}
            <Bold>{data?.attributes.service.service_name}</Bold>{" "}
            completed on
          </Trans>{" "}
          <Bold>
            {formatDate(data?.attributes.service.service_date)}
          </Bold>
        </Text>
        <Text>
          <Trans>
            Provider{" "}
            <Bold>{data?.attributes.provider.provider_name}</Bold>
          </Trans>
        </Text>

        <Divider />

        <Caption>
          <Trans>Balance due</Trans>
        </Caption>
        <Balance dir="auto">
          ${data?.attributes.amount.toLocaleString()}
        </Balance>
      </Box>

      {/* Proceed to payment block */}
      {(data?.attributes.state === "requested" ||
        data?.attributes.state === "failed") &&
        !isPending &&
        !isFail && (
          <Box>
            <Title>
              <Icon type="bank" />{" "}
              <Trans>Pay by bank transfer (ACH)</Trans>
            </Title>
            <NoteContainer>
              <Note>
                <NoteTitle>
                  <Trans>Why bank transfer (ACH)</Trans>
                </NoteTitle>
                <Text>
                  <Trans>
                    ACH payments are less expensive than credit card
                    transactions so we can avoid charging you any fee
                  </Trans>
                </Text>
              </Note>
              <Note>
                <NoteTitle>
                  <Trans>How it works</Trans>
                </NoteTitle>
                <Text>
                  <Trans>
                    Stork Club uses Plaid to securely verify your
                    account and complete the payment.
                  </Trans>
                </Text>
              </Note>
            </NoteContainer>

            <SubmitButton
              icon={<Icon type="lock" />}
              kind="filledCoral"
              onClick={callPlaidHandler}
            >
              <Trans>Connect your bank and pay now</Trans>
            </SubmitButton>
          </Box>
        )}

      <Links>
        <ExternalLink
          className="stripe-link"
          href="https://stripe.com"
        >
          <Trans>Powered by</Trans> <Icon type="stripe" />
        </ExternalLink>
        <ExternalLink
          className="plaid-link"
          href="https://plaid.com/"
        >
          <Icon type="plaid" />
        </ExternalLink>
        <LinkDivider />
        <Trans>
          <ExternalLink href="https://stripe.com/checkout/legal">
            Terms
          </ExternalLink>
        </Trans>
        <Trans>
          <ExternalLink href="https://stripe.com/privacy">
            Privacy
          </ExternalLink>
        </Trans>
      </Links>
    </Wrapper>
  );
};

export default Payment;
