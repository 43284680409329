import { isLeft } from "fp-ts/Either";
import * as t from "io-ts";
import { useMutation, useQueryClient } from "react-query";
import { Session } from "../../../authentication/hooks/useAuthSession";
import { useAxios } from "../../../network";
import { ENDPOINTS } from "../../../network/endpoints";
import { ProfileStatus, ProfileStatusC } from "../types";

const responseC = t.type({
  data: t.type({
    attributes: t.type({
      access_token: t.union([t.string, t.null]), // in rare case it can return null
      status: ProfileStatusC,
    }),
    id: t.string,
    type: t.literal("status_update"),
  }),
});
export function useUpdateFamilyStatus() {
  const client = useQueryClient();
  const axios = useAxios();
  return useMutation(
    async (status: ProfileStatus) => {
      const response = await axios.put<unknown>(
        `${ENDPOINTS.updateProfileStatus}`,
        {
          status,
        },
      );

      const decoded = responseC.decode(response.data);

      return isLeft(decoded) ? null : decoded.right;
    },
    {
      onSuccess: (data, status) => {
        const accessToken = data?.data.attributes.access_token;

        client.setQueryData<Session | undefined>(
          "cookie",
          (existingData) => {
            if (existingData?.user) {
              return {
                ...existingData,
                user: {
                  ...existingData.user,
                  attributes: {
                    ...existingData.user.attributes,
                    sendbird_credentials: {
                      ...existingData.user.attributes
                        .sendbird_credentials,
                      access_token: accessToken || null,
                    },
                    status,
                  },
                },
              };
            }
            return existingData;
          },
        );
      },
    },
  );
}
