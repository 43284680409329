/* eslint-disable react/no-unescaped-entities */
import { Trans } from "@lingui/macro";
import { FC, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import FamilyStatusForm from "../../../../components/FamilyStatusForm";
import { ProfileStatus } from "../../../../components/FamilyStatusForm/types";
import { useUpdateFamilyStatus } from "../../../../components/FamilyStatusForm/useUpdateFamilyStatus";
import { colors } from "../../../../constants/colors";
import { Title } from "../../../../lib";
import Button from "../../../../lib/Button";
import Icon from "../../../../lib/SharedIcon";
import mediaQuery from "../../../../utils/mediaQuery";
import Layout, { Content } from "../Layout";

const Top = styled.div`
  padding: 0 15px;
  text-align: center;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      text-align: start;
      padding: 0;
    `,
  )}
`;

const StyledTitle = styled(Title)`
  ${mediaQuery(
    "phone",
    css`
      font-size: 24px;
      line-height: 29px;
    `,
  )}
`;

const Description = styled.p`
  font-size: 15px;
  line-height: 24px;
  margin: 0;
`;
const Footnote = styled.p`
  color: ${colors.brownBlack};
  font-size: 12px;
  line-height: 16px;

  align-items: center;
  display: flex;
  gap: 6px;
  padding: 0 20px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 0;
    `,
  )}
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
`;

const Form = styled.form`
  padding: 0 15px;
  ${mediaQuery(
    "greaterThanPhone",
    css`
      padding: 0;
    `,
  )}
`;

const StyledLayout = styled(Layout)`
  ${Content} {
    max-width: 800px;
  }

  ${Form} {
    margin-top: 30px;
  }

  ${StyledTitle} {
    margin-bottom: 9px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-bottom: 30px;
      `,
    )}
  }

  ${Footnote} {
    margin-top: 20px;
  }
`;

export interface StatusScreenProps {
  availableStatuses: readonly ProfileStatus[];
  userStatus: ProfileStatus | null;
}

const StatusScreen: FC<StatusScreenProps> = ({
  availableStatuses,
  userStatus,
}) => {
  const [status, setStatus] = useState<ProfileStatus | null>(null);
  const { mutate: updateStatus, isLoading } = useUpdateFamilyStatus();

  const handleClick = useCallback(
    (selectedStatus: ProfileStatus) => {
      if (isLoading) return;
      setStatus(selectedStatus);
    },
    [isLoading],
  );

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      if (!status) return;
      updateStatus(status);
    },
    [updateStatus, status],
  );

  return (
    <StyledLayout title="">
      <Top>
        <StyledTitle level="h1">
          {userStatus ? (
            <Trans>Update your current status</Trans>
          ) : (
            <Trans>Choose your current status</Trans>
          )}
        </StyledTitle>
        <Description>
          <Trans>
            To personalize your experience with curated care, experts,
            and resources.
          </Trans>
        </Description>
      </Top>

      <Form onSubmit={handleSubmit}>
        <FamilyStatusForm
          availableStatuses={availableStatuses}
          onChange={handleClick}
          status={status}
        />
        <ButtonContainer>
          <Button disabled={!status} kind="filledCoral" type="submit">
            <Trans>Continue</Trans>
          </Button>
        </ButtonContainer>
      </Form>

      <Footnote>
        <Icon type="lock" />
        <Trans>
          This information will always stay private and you can always
          change your status later
        </Trans>
      </Footnote>
    </StyledLayout>
  );
};

export default StatusScreen;
