import {
  CSSProperties,
  FC,
  Fragment,
  ReactNode,
  useMemo,
} from "react";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../constants";
import mediaQuery from "../../../utils/mediaQuery";
import { FormFieldContext, FormFieldContextValue } from "./context";

export const ChildrenRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const LabelText = styled.span<{
  $formGroup: boolean;
  $hidden: boolean;
  $disabled: boolean;
  $error: boolean;
}>`
  appearance: none;
  color: ${colors.brownGrey};
  display: inline;
  font-family: ${fonts.main};
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;

  ${({ $disabled }) =>
    $disabled
      ? css`
          color: ${colors.brownGrey};
          opacity: 0.6;
        `
      : undefined}
  ${({ $error }) =>
    $error
      ? css`
          color: ${colors.red};
        `
      : undefined}
  ${({ $hidden }) =>
    $hidden
      ? css`
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          opacity: 1;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        `
      : undefined}

  ${({ $formGroup }) =>
    $formGroup
      ? css`
          float: left;
        `
      : undefined}
`;

export const Error = styled.div`
  color: ${colors.red};
  font-family: ${fonts.main};
  font-size: 12px;
  line-height: 16px;
`;

export const Label = styled.label<{
  disabled?: boolean;
  $formGroup: boolean;
  $layout: NonNullable<FormFieldProps["layout"]>;
  required?: boolean;
}>`
  appearance: none;
  border: none;
  display: flex;
  flex-direction: ${({ $layout }) =>
    $layout === "mixed" || $layout === "column" ? "column" : "row"};
  margin: 0;
  padding: 0;

  /*
  &:focus-within ${LabelText} {
    color: ${colors.blue};
  }
  */
`;
export const Description = styled.div`
  color: ${colors.brownGrey};
  font-size: 16px;
  line-height: 19px;
`;
export const Container = styled.div<{
  $layout: NonNullable<FormFieldProps["layout"]>;
}>`
  display: flex;
  flex-direction: column;

  position: relative;
  ${Error} {
    &:not(:empty) {
      margin-top: 4px;
    }
  }
  ${Description} {
    margin-top: 4px;
  }

  /* row layout only in desktop */
  /*
  ${({ $layout }) =>
    $layout === "row" &&
    css`
      ${mediaQuery(
        "greaterThanPhone",
        css`
          align-items: center;
          flex-direction: row;
          ${Label} {
            align-items: center;
          }
          ${LabelText} {
            margin-inline-end: 12px;
          }
          ${Error} {
            margin-inline-start: 8px;
            margin-top: 0;
          }
          ${Description} {
            margin-inline-start: 8px;
            margin-top: 0;
          }
        `,
      )}
    `}
  */
`;

export interface FormFieldProps {
  className?: string;
  labelTextClassName?: string;
  style?: CSSProperties;
  labelTextStyle?: CSSProperties;
  /**
   * Required! You must present the label
   * @see: https://www.w3.org/WAI/tutorials/forms/labels/
   */
  label: string;
  disabled?: boolean;
  layout?: "column" | "mixed" | "row";
  /**
   * In some cases we do not want to show a label.
   * In this case the <label /> will be **technically** hidden (but **not** display: none, or visibility: hidden)
   * @see: https://www.w3.org/WAI/tutorials/forms/labels/
   */
  hideLabel?: boolean;
  /**
   * In formGroup mode we use `<fieldset />` instead of `<label />`
   */
  formGroup?: boolean;
  error?: string;
  description?: ReactNode;
  children: ReactNode;
  /**
   * make sense only if you have formGroup: true
   */
  required?: boolean;
}
const FormField: FC<FormFieldProps> = ({
  className,
  style,
  label,
  labelTextClassName,
  labelTextStyle,
  layout = "column",
  disabled = false,
  hideLabel = false,
  error,
  description,
  formGroup,
  required,
  children,
}) => {
  const contextValue: FormFieldContextValue = useMemo(
    () => ({
      "aria-invalid": !!error,
      disabled,
    }),
    [disabled, error],
  );

  const ChildrenWrapper = layout === "mixed" ? ChildrenRow : Fragment;
  return (
    <FormFieldContext.Provider value={contextValue}>
      <Container $layout={layout} className={className} style={style}>
        <Label
          $formGroup={!!formGroup}
          $layout={layout}
          as={formGroup ? "fieldset" : undefined}
          disabled={formGroup ? disabled : undefined}
          required={formGroup ? required : undefined}
        >
          <LabelText
            $disabled={disabled}
            $error={!!error}
            $formGroup={!!formGroup}
            $hidden={hideLabel}
            // todo: fix <legend> behavior in safari
            // https://stackoverflow.com/a/59425373/2807660
            as={formGroup ? "legend" : undefined}
            className={labelTextClassName}
            style={labelTextStyle}
          >
            {label}
          </LabelText>
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </Label>
        <Error role="alert">{error}</Error>
        {description && <Description>{description}</Description>}
      </Container>
    </FormFieldContext.Provider>
  );
};

export default FormField;
