/* eslint-disable max-lines */
import { t, Trans } from "@lingui/macro";
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import CookieYesButton from "../../../components/CookieYesButton";
import { colors } from "../../../constants";
import FormField from "../../../lib/forms/FormField";
import Select from "../../../lib/forms/primitives/Select";
import LocaleSwitcher from "../../../lib/LocaleSwitcher";
import { Logo } from "../../../lib/Logo";
import PageTitle from "../../../lib/PageTitle";
import Spinner from "../../../lib/Spinner";
import { JSONApiErrorsException } from "../../../network/jsonApi/core/JSONApiErrorsPayload";
import mediaQuery from "../../../utils/mediaQuery";
import renderError from "../../../utils/renderError";
import { networkifyMixin } from "../Layout";
import { Header, StyledButton } from "../styles";
import Cobra, { Container as CobraContainer } from "./Cobra";
import { useCheckSignUpToken } from "./hooks";
import SignUpForm from "./SignUpForm";

const StyledLogo = styled(Logo)``;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  grid-area: content;
  ${StyledButton} {
    margin-top: 40px;
    width: 100%;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        width: auto;
      `,
    )};
  }
  ${CobraContainer} {
    margin-bottom: 48px;
  }
`;

type FormCountrySelectProps = {
  $hidden: boolean;
};
const FormCountrySelect = styled(FormField)<FormCountrySelectProps>`
  display: ${({ $hidden }) => ($hidden ? "none" : "block")};
  margin-bottom: 24px;
  width: 100%;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      width: 370px;
    `,
  )}
`;

const Container = styled.main`
  display: grid;
  grid-template-areas:
    "logo"
    "content";
  max-width: 1050px;
  padding: 16px;

  ${mediaQuery(
    "desktopMedium",
    css`
      padding-block-end: 70px;
      padding-block-start: 70px;
      padding-inline-end: 20px;
      padding-inline-start: 135px;
    `,
  )};

  ${StyledLogo} {
    margin-bottom: 45px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-bottom: 75px;
      `,
    )};
  }

  ${Header} {
    margin-bottom: 40px;
    ${mediaQuery(
      "phone",
      css`
        font-size: 20px;
        line-height: 24px;
      `,
    )};
  }

  .labelText {
    margin-bottom: 12px;
  }

  ${networkifyMixin};
`;

const SpinnerWrapper = styled.div`
  padding-top: 40px;
`;

const CookieSettings = styled.div`
  margin-top: 40px;

  /* Styled CookieYes button */
  button {
    color: ${colors.brownGrey};
    display: inline-flex;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
  }
`;
export interface FormProps {
  firstName: string;
  lastName: string;
  mobilePhone: string;
  zip: string;
  sex: "female" | "male";
  gender: "female" | "male" | "no_answer" | "other";
  month: number;
  day: number;
  year: number;
  password: string;
  confirmPassword: string;
  partnerEmail: string | null | undefined;
  companyId: string;
  isRead: boolean;
}

const SELECTED_COMPANY_ID_SESSION_KEY =
  "SignUpScreen_SelectedCompanyId";

const SignUpScreen: FC = () => {
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<
    string | undefined
  >();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const customerCode = searchParams.get("code");
  const emailSignUpToken = searchParams.get("email_signup_token");
  const partnerInvitationToken = searchParams.get(
    "partner_invitation_token",
  );
  const handleInvalidTokenError = useCallback(
    (error?: unknown) => {
      let message: string;
      if (error instanceof JSONApiErrorsException) {
        message = error.errors[0]?.title || error.message;
      } else if (error) {
        message = renderError(error);
      } else {
        message = t`The invitation you're trying to use can't be found or expired. Please start again.`;
      }
      navigate(
        customerCode ? `/signup?code=${customerCode}` : "/signup",
        {
          state: {
            invalidTokenError: message,
          },
        },
      );
    },
    [customerCode, navigate],
  );

  useEffect(() => {
    // TODO: use error boundary to redirect (throw an Error and do not render the rest of the form) and remove "enabled" flag from the useCheckSignUpToken
    if (!emailSignUpToken && !partnerInvitationToken) {
      handleInvalidTokenError();
    }
  }, [
    emailSignUpToken,
    handleInvalidTokenError,
    partnerInvitationToken,
  ]);
  const {
    data: checkSignUpTokenData,
    isLoading: checkSignUpTokenIsLoading,
    error: checkSignUpTokenError,
  } = useCheckSignUpToken({
    emailSignUpToken,
    partnerInvitationToken,
  });

  const askEmployeeId = !!checkSignUpTokenData?.askEmployeeId;

  useEffect(() => {
    if (checkSignUpTokenError) {
      handleInvalidTokenError(checkSignUpTokenError);
    }
  }, [
    checkSignUpTokenError,
    handleInvalidTokenError,
    partnerInvitationToken,
  ]);

  useLayoutEffect(() => {
    // as soon as checkSignUpTokenData is loaded,
    // we can check if we have a selected company in session
    // and if it's still valid
    //
    // if selectedCompanyId is set, we don't need to do anything
    if (
      !selectedCompanyId &&
      checkSignUpTokenData &&
      checkSignUpTokenData.companies.length > 0
    ) {
      const selectedCompanyIdFromSession = sessionStorage.getItem(
        SELECTED_COMPANY_ID_SESSION_KEY,
      );
      if (
        selectedCompanyIdFromSession &&
        checkSignUpTokenData.companies.find(
          (c) =>
            String(c.id) === String(selectedCompanyIdFromSession),
        )
      ) {
        setSelectedCompanyId(selectedCompanyIdFromSession);
      } else {
        setSelectedCompanyId(checkSignUpTokenData.companies[0].id);
      }
    }
  }, [checkSignUpTokenData, selectedCompanyId]);

  const countriesOptions =
    checkSignUpTokenData?.companies.map((item) => ({
      label: item.attributes.country_name,
      value: item.id,
    })) || [];

  if (checkSignUpTokenIsLoading) {
    return (
      <SpinnerWrapper>
        <Spinner color={colors.brownLightGrey1} size="40px" />
      </SpinnerWrapper>
    );
  }

  const selectedCompany = checkSignUpTokenData?.companies.find(
    (c) => String(c.id) === String(selectedCompanyId),
  );
  const partnerInvitationEnabled =
    selectedCompany?.attributes.partner_invitation_enabled ?? true;

  const isUS: boolean =
    selectedCompany?.attributes.country_code === "US";

  const minPasswordLength = selectedCompany
    ? selectedCompany.attributes.password_min_length
    : 8;

  return (
    <Container>
      <Top>
        <StyledLogo fill="#2d4f61" height={31} width={207} />
        <LocaleSwitcher
          // all languages provided by the available companies
          localesProvided={checkSignUpTokenData?.companies.reduce(
            (acc: string[], item) => [
              ...acc,
              ...item.attributes.languages,
            ],
            [],
          )}
        />
      </Top>
      <FormContainer>
        <>
          <PageTitle>Let&apos;s customize your experience</PageTitle>
          <Header>
            <Trans>Let&apos;s customize your experience</Trans>
          </Header>
          <FormCountrySelect
            $hidden={countriesOptions.length < 2 || askEmployeeId}
            formGroup
            label={t`Country`}
            labelTextClassName="labelText"
          >
            <Select
              aria-label={t`Country`}
              isDisabled={countriesOptions.length < 2}
              isMulti={false}
              isNetworkStyle
              // onBlur={onBlur}
              onChange={(option) => {
                if (option) {
                  sessionStorage.setItem(
                    SELECTED_COMPANY_ID_SESSION_KEY,
                    option.value,
                  );
                } else {
                  sessionStorage.removeItem(
                    SELECTED_COMPANY_ID_SESSION_KEY,
                  );
                }

                setSelectedCompanyId(option?.value);
              }}
              options={countriesOptions}
              placeholder={t`Country`}
              value={countriesOptions.find(
                (m) => m.value === selectedCompany?.id,
              )}
            />
          </FormCountrySelect>
          {isUS && <Cobra />}
          {checkSignUpTokenData && selectedCompany && (
            <SignUpForm
              askEmployeeId={askEmployeeId}
              companyCareEmail={
                selectedCompany.attributes.care_team_email || null
              }
              companyId={selectedCompany.id}
              emailSignUpToken={emailSignUpToken}
              isUS={isUS}
              minPasswordLength={minPasswordLength}
              partnerInvitationEnabled={partnerInvitationEnabled}
              partnerInvitationToken={partnerInvitationToken}
            />
          )}
          <CookieSettings>
            <CookieYesButton />
          </CookieSettings>
        </>
      </FormContainer>
    </Container>
  );
};

export default SignUpScreen;
