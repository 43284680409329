import { FC } from "react";
import styled, { css } from "styled-components";
import Skeleton from "../../../lib/Skeleton";
import Title from "../../../lib/Title";
import mediaQuery from "../../../utils/mediaQuery";
import ScrollHandler from "../../ScrollHandler";
import { Article } from "../models/Article";
import Item from "./Item";
import ItemLarge from "./ItemLarge";

const Container = styled.div`
  ${mediaQuery(
    "greaterThanPhone",
    css`
      max-width: 670px;
    `,
  )}
  ${mediaQuery(
    "desktopMedium",
    css`
      max-width: 800px;
    `,
  )}

  & + & {
    margin-top: 30px;
    ${mediaQuery(
      "greaterThanPhone",
      css`
        margin-top: 50px;
      `,
    )}
  }

  &:last-child {
    ${mediaQuery(
      "phone",
      css`
        padding-bottom: 30px;
      `,
    )}
  }

  & > ${Title} {
    margin-bottom: 30px;
  }
`;

export const SmallItems = styled.div`
  margin: 20px -20px 0;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100vw;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      box-sizing: border-box;
      margin: 20px 0 0;
      margin-inline-start: -40px;
      padding-inline-start: 40px;
      width: calc(100vw - var(--sidebar-width));
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      width: calc(100vw - var(--sidebar-width) - 40px);
    `,
  )}
`;

const SmallItemsRibbon = styled.div<{ length: number }>`
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  padding: 0 20px;
  width: ${({ length }) => length * 210 + 20 * (length - 1) + 40}px;

  ${mediaQuery(
    "greaterThanPhone",
    css`
      margin: initial;
      padding: initial;
    `,
  )}

  ${mediaQuery(
    "greaterThanTablet",
    css`
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 210px 210px 210px;
      margin: initial;
      padding: initial;
      width: 100%;
    `,
  )}

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-gap: 19px;
      grid-template-columns: 254px 254px 254px;
    `,
  )}
`;

type Props = {
  data?: Article[];
  title: string;
  isLoading: boolean;
};

const Items: FC<Props> = ({ data, title, isLoading }: Props) => {
  const [firstItem, ...restItems] = data || [];

  return (
    <Container>
      <Skeleton active loading={isLoading}>
        {firstItem ? (
          <ScrollHandler key={title}>
            <Title as="h2">{title}</Title>
            <ItemLarge {...firstItem} />

            <SmallItems>
              <SmallItemsRibbon length={restItems.length}>
                {restItems.map((article) => (
                  <Item {...article} key={article.id} />
                ))}
              </SmallItemsRibbon>
            </SmallItems>
          </ScrollHandler>
        ) : null}
      </Skeleton>
    </Container>
  );
};

export default Items;
