import { Configuration } from "rollbar";

export const rollbarConfig: Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
  addErrorContext: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: !!(
    import.meta.env.VITE_ROLLBAR_ENV &&
    import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM_TOKEN
  ),
  payload: {
    client: {
      javascript: {
        code_version: import.meta.env.VITE_GIT_SHA,
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
    environment: import.meta.env.VITE_ROLLBAR_ENV,
  },
  scrubTelemetryInputs: true, // do not include sensitive information in telemetry
  version: import.meta.env.VITE_VERSION || undefined,
};
