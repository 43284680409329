/* eslint-disable max-lines */
import { t, Trans } from "@lingui/macro";
import {
  FC,
  HTMLAttributes,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import useAuthSession from "../../../authentication/hooks/useAuthSession";
import { useSignOut } from "../../../authentication/hooks/useSignOut";
import { colors, fonts } from "../../../constants";
import Icon from "../../../lib/Icon";
import { LogoSidebar } from "../../../lib/Logo";
import useGetCategories from "../../articles/hooks/useGetCategories";
import { useGetCompanyPolicies } from "../../company-policies/hooks";
import { useGetProgramsCategories } from "../../company-programs/hooks/categories";
import { usePartnerInvitationEnabled } from "../../ProfilePage/AddPartner/hooks";
import UnreadChatBadge from "./UnreadChatBadge";
import UnreadNotificationsBadge from "./UnreadNotificationsBadge";

const StyledLink = styled(NavLink)`
  align-items: center;
  color: ${colors.black};
  display: inline-flex;
  font-family: ${fonts.main};
  font-size: 18px;
  text-decoration: none;
  word-break: break-word;

  &.active,
  &:hover {
    color: ${colors.blue};
  }
`;

const StyledLi = styled.li`
  line-height: 24px;
  list-style: none;
  margin-bottom: 0;
  padding: 0 0 19px;
`;

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const InnerContainer = styled.div`
  background: ${colors.white};
  border-inline-end: 1px solid ${colors.brownLightGrey1};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  inset-inline-start: 0;
  position: fixed;
  top: 0;
  width: var(--sidebar-width);
`;
const Container = styled.aside``;

const LogoContainer = styled.div`
  padding: 30px 30px 20px;
  position: relative;
`;

const ScrollableMenuContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;

  /* gmail implementation */
  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
    border-radius: 4px;
    box-shadow: none;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(32, 33, 36, 0.38);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(32, 33, 36, 0.541);
  }
`;

const Top = styled.div`
  flex: 1;
  padding: 10px 30px 0;
`;

const TopGradient = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.08) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  bottom: -6px;
  height: 6px;
  left: 0px;
  opacity: 0;
  position: absolute;
  width: 100%;
`;

const StyledLogo = styled(LogoSidebar)`
  margin-inline-start: -2px;
`;

const Bottom = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 0 10px 10px;
`;

const StyledInviteIcon = styled(Icon)`
  fill: ${colors.black};
  height: 10px;
  width: 10px;
`;

const StyledButton = styled(Link)`
  align-items: center;
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-sizing: border-box;
  color: ${colors.black};
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 20px;
  padding-block: 16px 13px;
  padding-inline: 20px 22px;
  position: relative;
  text-decoration: none;
  transition: 0.075s ease-in-out;

  &:hover {
    border-color: ${colors.blue};
    color: ${colors.blue};
    ${StyledInviteIcon} {
      fill: ${colors.blue};
    }
  }
`;

const StyledUsernameButton = styled(StyledButton)`
  display: block;
  width: 219px;
`;

const StyledLogoutIcon = styled(Icon)`
  fill: ${colors.black};
  height: 18px;
  width: 15px;
`;

const ButtonIconLogout = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  inset-inline-end: 19px;
  padding: 2px;
  position: absolute;
  top: 16px;
  transition: 0.075s ease-in-out;

  &:hover ${StyledLogoutIcon} {
    fill: ${colors.blue};
  }
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const Username = styled.span`
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  &::after {
    background: linear-gradient(
      to right,
      transparent 0%,
      ${colors.white} 60%
    );
    content: "";
    height: 100%;
    inset-inline-end: 0;
    position: absolute;
    top: 0;
    width: 60px;
  }
  [dir="rtl"] &::after {
    background: linear-gradient(
      to left,
      transparent 0%,
      ${colors.white} 60%
    );
  }
`;

const CompanyProgramsLine = styled.hr`
  background: ${colors.brownLightGrey1};
  border: none;
  height: 1px;
  margin: 0 0 20px;
  padding: 0;
`;

const CompanyProgramsTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 0 0 18px;
`;

const Sidebar: FC<HTMLAttributes<HTMLElement>> = ({
  ...restProps
}) => {
  const scrollableMenuRef = useRef<HTMLDivElement | null>(null);
  const topGradientRef = useRef<HTMLDivElement | null>(null);
  const topGradientOpacity = useRef<string>("0");
  const { data: authSession } = useAuthSession();
  const { mutate: signOut, isLoading: signOuInProcess } =
    useSignOut();
  const onLogout = useCallback(() => {
    signOut();
  }, [signOut]);

  useEffect(() => {
    const onScroll = () => {
      if (!scrollableMenuRef.current || !topGradientRef.current)
        return;

      const { scrollTop } = scrollableMenuRef.current;
      if (scrollTop > 10) {
        topGradientOpacity.current = "1";
      } else {
        topGradientOpacity.current = "0";
      }
      topGradientRef.current.style.opacity =
        topGradientOpacity.current;
    };

    onScroll();
    const el = scrollableMenuRef.current;
    el?.addEventListener("scroll", onScroll);

    return () => {
      el?.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { data: articlesCategories } = useGetCategories();
  const { data: companyPolicies } = useGetCompanyPolicies();
  const { data: programsCategories } = useGetProgramsCategories();
  const partnerInvitationEnabled = usePartnerInvitationEnabled();

  return (
    <Container {...restProps}>
      <InnerContainer>
        <LogoContainer>
          <Link aria-label={t`Open the home page`} to="/">
            <StyledLogo />
          </Link>
          <TopGradient
            ref={topGradientRef}
            style={{ opacity: topGradientOpacity.current }}
          />
        </LogoContainer>
        <ScrollableMenuContainer ref={scrollableMenuRef}>
          <Top>
            <StyledUl>
              <StyledLi>
                <StyledLink aria-label={t`Open the home page`} to="/">
                  <Trans>Home</Trans>
                </StyledLink>
              </StyledLi>
              <StyledLi>
                <StyledLink to="/care-navigator">
                  <Trans>Care Navigator</Trans> <UnreadChatBadge />
                </StyledLink>
              </StyledLi>
              {!!authSession?.user?.notificationsEnabled && (
                <StyledLi>
                  <StyledLink to="/notifications">
                    <Trans>Notifications</Trans>{" "}
                    <UnreadNotificationsBadge />
                  </StyledLink>
                </StyledLi>
              )}
              <StyledLi>
                <StyledLink to="/talk-to-experts">
                  <Trans>Talk to experts</Trans>
                </StyledLink>
              </StyledLi>
              {articlesCategories &&
                articlesCategories.length > 0 && (
                  <StyledLi>
                    <StyledLink to="/articles">
                      <Trans>Articles</Trans>
                    </StyledLink>
                  </StyledLi>
                )}
              {companyPolicies && companyPolicies.length > 0 && (
                <StyledLi>
                  <StyledLink to="/company-policies">
                    <Trans>Company policies</Trans>
                  </StyledLink>
                </StyledLi>
              )}

              {programsCategories &&
                programsCategories.length > 0 && (
                  <>
                    <StyledLi css="padding: 0">
                      <CompanyProgramsLine />
                      <CompanyProgramsTitle>
                        <Trans>Covered programs</Trans>
                      </CompanyProgramsTitle>
                      <StyledUl>
                        {programsCategories.map((item) => (
                          <StyledLi key={item.id}>
                            <StyledLink to={`/programs/${item.slug}`}>
                              {item.name}
                            </StyledLink>
                          </StyledLi>
                        ))}
                      </StyledUl>
                    </StyledLi>
                  </>
                )}
            </StyledUl>
          </Top>

          <Bottom>
            {!authSession?.user?.attributes.partner &&
              partnerInvitationEnabled && (
                <StyledButton to="/profile/add-partner">
                  <Trans>Invite partner</Trans>
                  <StyledInviteIcon type="invite" />
                </StyledButton>
              )}

            <RelativeContainer>
              <StyledUsernameButton
                aria-label={t`Open my profile`}
                to="/profile"
              >
                <Username>
                  {authSession?.user?.attributes.preferred_name ||
                    authSession?.user?.attributes.name}
                </Username>
              </StyledUsernameButton>
              <ButtonIconLogout
                aria-label={t`Logout`}
                disabled={signOuInProcess}
                onClick={onLogout}
                type="button"
              >
                <StyledLogoutIcon type="logout" />
              </ButtonIconLogout>
            </RelativeContainer>
          </Bottom>
        </ScrollableMenuContainer>
      </InnerContainer>
    </Container>
  );
};

export default styled(Sidebar)``;
