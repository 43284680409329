import { t, Trans } from "@lingui/macro";

import { FC, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import useAuthSession from "../../authentication/hooks/useAuthSession";
import { fonts } from "../../constants";
import { HomeLocaleSelect } from "../../lib/LocaleSelect";
import { useMessagesApi } from "../../lib/messages";
import PageTitle from "../../lib/PageTitle";
import ProfileButton from "../../lib/ProfileButton";
import Skeleton from "../../lib/Skeleton";
import Tabs, { TabInterface } from "../../lib/Tabs";
import Title from "../../lib/Title";
import {
  NotificationsWidget,
  Container as NotificationsWidgetContainer,
} from "../../notifications/components/Widget";
import { getAuthorization } from "../../notifications/hooks/getAuthorization";
import {
  getLatestNotifications,
  Auth,
  Reimb,
} from "../../notifications/hooks/useGetMainPageNotifications";
import { getMainPageReimbursement } from "../../reimbursements/hooks/toGetReimbursementsResult";
import mediaQuery from "../../utils/mediaQuery";
import renderError from "../../utils/renderError";
import PageLayout, {
  Body,
  Footer,
  Header,
  Sidebar,
} from "../PageLayout";
import { Wrapper } from "../PageLayout/Header";
import { CareNavigatorWelcome } from "./components/CareNavigatorWelcome";
import ChangeFamilyStatus from "./components/ChangeFamilyStatus";
import Tab from "./components/Tab";
import { useHomeData } from "./hooks/useHomeData";

const StyledLocaleSelect = styled(HomeLocaleSelect)`
  position: static;

  ${mediaQuery(
    "greaterThanTablet",
    css`
      position: absolute;
      inset-inline-end: 0;
      top: -4px;
      z-index: 1;
    `,
  )}
`;

const HeaderContent = styled.div`
  width: 100%;

  ${NotificationsWidgetContainer} {
    margin-top: 20px;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  position: relative;
  row-gap: 20px;

  ${mediaQuery(
    "greaterThanTablet",
    css`
      display: block;
    `,
  )}
`;

const StyledTabs = styled(Tabs)``;

const TabsTitle = styled(Title).attrs({
  as: "div",
  level: "h4",
})`
  background: white;
  font-family: ${fonts.main};
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  padding-left: var(--content-margin-side);
  padding-bottom: 10px;
`;

const StyledBody = styled(Body)`
  ${StyledTabs} {
    ${mediaQuery(
      "greaterThanPhone",
      css`
        padding-top: 20px;
      `,
    )}
  }
`;

const StyledPageLayout = styled(PageLayout)`
  ${Wrapper} {
    ${mediaQuery(
      "phone",
      css`
        padding-top: 15px;
      `,
    )}
  }
`;

const HomeScreen: FC = () => {
  const { data: authSession, isLoading: isAuthSessionLoading } =
    useAuthSession();

  const notificationsEnabled =
    !!authSession?.user?.notificationsEnabled;

  const currentFamilyStatus = authSession?.user?.attributes?.status;
  const expectingABaby: boolean =
    authSession?.user?.attributes.status === "expecting_a_baby";

  const { data, isLoading, error } = useHomeData(currentFamilyStatus);
  const availableStatuses = useMemo(
    () => authSession?.user?.availableStatuses || [],
    [authSession?.user?.availableStatuses],
  );

  const messages = useMessagesApi();

  useEffect(() => {
    if (error) {
      messages.error({
        content: renderError(error),
      });
    }
  }, [error, messages]);

  const careNavigator = data?.relationships.care_navigator;

  const activeTabKey: string | undefined = data?.attributes
    .default_tab_id
    ? data.attributes.default_tab_id.toString()
    : undefined;

  const tabs: TabInterface[] = useMemo(() => {
    const apiTabs = data?.relationships.tabs || [];
    return apiTabs.map((tab) => ({
      component: <Tab tab={tab} />,
      key: tab.id,
      label: `${expectingABaby ? `${t`Your month`}: ` : ""}${
        tab.attributes.name
      }`,
    }));
  }, [data?.relationships.tabs, expectingABaby]);

  const notifications: Array<Auth | Reimb> = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let notificationsTempArr: any[] = [];
    if (data?.relationships.notifications) {
      notificationsTempArr = notificationsTempArr.concat(
        data?.relationships.notifications,
      );
    }
    if (data?.relationships.reimbursements) {
      notificationsTempArr = notificationsTempArr.concat(
        data?.relationships.reimbursements,
      );
    }

    const authorizations = notificationsTempArr
      .map((notification) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
        return notification.type === "notification"
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            getAuthorization(notification) // API response is simplified but adapters does not know about it. Surprisingly, it is not a problem.
          : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            getMainPageReimbursement(notification);
      })
      .sort(
        (a, b) =>
          b.events[0].createdAt.getTime() -
          a.events[0].createdAt.getTime(),
      )
      .slice(0, 2);

    return getLatestNotifications(authorizations);
  }, [
    data?.relationships.notifications,
    data?.relationships.reimbursements,
  ]);

  const companyLanguages =
    authSession?.user?.company.attributes.languages;

  return (
    <StyledPageLayout footer={<Footer />} sidebar={<Sidebar />}>
      <Header>
        <PageTitle>{t`Stork Club`}</PageTitle>

        <HeaderContent>
          <HeaderTop>
            <StyledLocaleSelect
              defaultLanguage={
                authSession?.user?.attributes.preferred_language
              }
              languages={companyLanguages}
              userRegion={
                authSession?.user?.company.attributes.country_code
              }
            />

            <ProfileButton />

            <Skeleton active loading={isAuthSessionLoading}>
              {currentFamilyStatus && (
                <ChangeFamilyStatus
                  availableStatuses={availableStatuses}
                  currentStatus={currentFamilyStatus}
                />
              )}
            </Skeleton>
          </HeaderTop>

          <Skeleton active loading={isLoading}>
            {error || !careNavigator || !authSession?.user ? null : (
              <CareNavigatorWelcome
                avatar={careNavigator.attributes.image_set}
                chatLink="/care-navigator"
                fname={careNavigator.attributes.name.split(" ")[0]}
                lname={careNavigator.attributes.name.split(" ")[1]}
                scheduleLink={`/schedule/calls/new?call_expert_id=${careNavigator.id}&category=chat_with_care_navigator`}
                userName={authSession.user.attributes.name}
              />
            )}
          </Skeleton>

          {!isLoading && notificationsEnabled && (
            <NotificationsWidget notifications={notifications} />
          )}
        </HeaderContent>
      </Header>

      <StyledBody>
        {expectingABaby && tabs.length > 0 && (
          <TabsTitle>
            <Trans>How many months are you pregnant?</Trans>
          </TabsTitle>
        )}

        <StyledTabs
          defaultActiveKey={activeTabKey}
          tabs={tabs}
          withDefaultPadding
        />
      </StyledBody>
    </StyledPageLayout>
  );
};

export default HomeScreen;
