/* eslint-disable max-lines */
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Trans, t } from "@lingui/macro";
import {
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAuthSession from "../../authentication/hooks/useAuthSession";
import { colors } from "../../constants";
import Alert from "../../lib/Alert";
import Button from "../../lib/Button";
import TextArea from "../../lib/forms/primitives/TextArea";
import Icon from "../../lib/Icon";
import Link from "../../lib/Link";
import { useMessagesApi } from "../../lib/messages";
import PageTitle from "../../lib/PageTitle";
import SharedIcon from "../../lib/SharedIcon";
import Skeleton from "../../lib/Skeleton";
import { Text } from "../../lib/Text";
import Title from "../../lib/Title";
import { JSONApiErrorsException } from "../../network/jsonApi/core/JSONApiErrorsPayload";
import { formatDate } from "../../utils/date-fns";
import { useHandleSubmitImpl } from "../../utils/forms";
import renderError from "../../utils/renderError";
import useLocationQuery from "../../utils/useLocationQuery";
import PageLayout, { Body, Footer, Sidebar } from "../PageLayout";
import Disclaimer from "./Disclaimer";
import { useGetCallSubject } from "./hooks/useGetCallSubject";
import { useSchedule } from "./hooks/useSchedule";
import {
  BackButton,
  BackButtonContainer,
  ButtonsRow,
  Container,
  DateFormField,
  ExpertFormField,
  NoteField,
  SelectedDate,
  StyledDates,
  StyledExperts,
  StyledHeader,
  StyledTimes,
  SubTitle,
  TimeFormField,
  TimeWarn,
  TopBlock,
} from "./styles";

interface FormProps {
  expert: string | null;
  date: Date | null;
  time: Date | null;
  note: string | null;
}
const getResolver = () => {
  const FormSchema = yup.object().shape({
    date: yup.date().required(t`Please choose a date`),
    expert: yup.string().required(t`Please choose an expert`),
    note: yup.string(),
    time: yup.date().required(t`Please choose a time`),
  });
  return yupResolver(FormSchema);
};

const CallSubjects: FC = () => {
  const query = useLocationQuery();
  const session = useAuthSession();
  const navigate = useNavigate();

  const expertId = query.get("expert") || "";
  const category = query.get("category") || "";
  const {
    data,
    isLoading,
    error: initialLoadError,
  } = useGetCallSubject(category);

  const messagesApi = useMessagesApi();
  useEffect(() => {
    if (initialLoadError) {
      messagesApi.error({
        content: renderError(initialLoadError),
      });
    }
  }, [initialLoadError, messagesApi]);

  const resolver = useMemo(() => getResolver(), []);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      date: null,
      expert: null,
      note: "",
      time: null,
    },

    resolver,
  });

  useEffect(() => {
    if (expertId) {
      setValue("expert", expertId);
    }
  }, [expertId, setValue]);

  const selectedExpert = watch("expert", null);
  const selectedDate = watch("date", null);
  const selectedTime = watch("time", null);

  const [showNote, setShowNote] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const {
    mutate: scheduleCall,
    isLoading: submitting,
    error: scheduleError,
  } = useSchedule();
  const onSubmit = useCallback(
    (variables: FormProps) => {
      const { expert, time, note } = variables;
      if (!expert || !time) {
        // eslint-disable-next-line no-console
        console.error("Broken form?", variables);
      } else {
        scheduleCall(
          {
            category,
            expertId: expert,
            note: note || "",
            time,
          },
          {
            onSuccess: () => {
              setSubmitted(true);
            },
          },
        );
      }
    },
    [category, scheduleCall],
  );

  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit);
  const error = internalError;

  const dateRef = useRef<HTMLDivElement>(null);
  const timeRef = useRef<HTMLDivElement>(null);
  const firstContinueRef = useRef<HTMLDivElement>(null);

  const timeZone = session.data?.user?.attributes.timezone;

  return (
    <PageLayout
      footer={<Footer />}
      header={
        <StyledHeader>
          <BackButtonContainer>
            <BackButton onClick={() => navigate(-1)}>
              <Icon fill={colors.brownGrey} type="back" />
            </BackButton>
          </BackButtonContainer>
          <PageTitle>Schedule a call</PageTitle>
          <Title level="h1">
            {expertId ? (
              <Trans>Reschedule a call</Trans>
            ) : (
              <Trans>Choose an expert</Trans>
            )}
          </Title>
        </StyledHeader>
      }
      sidebar={<Sidebar />}
    >
      {timeZone && (
        <Body>
          <Container onSubmit={handleSubmitImpl}>
            <Skeleton active loading={isLoading}>
              {data ? (
                <>
                  <TopBlock>
                    <>
                      <SubTitle as="h2" styleLevel="h3">
                        {data.attributes.call_subject_type_name}
                      </SubTitle>
                      <p>
                        <Trans>
                          If you have troubles deciding, ask your{" "}
                          <Link to="/care-navigator">
                            Care&nbsp;Navigator
                          </Link>{" "}
                          to help you.
                        </Trans>
                      </p>
                      {error && (
                        <Alert status="error">
                          {renderError(error)}
                        </Alert>
                      )}
                      {!expertId && (
                        <ExpertFormField
                          error={errors.expert?.message}
                          formGroup
                          hideLabel
                          label={t`Choose an expert`}
                          labelTextClassName="label"
                          required
                        >
                          <Controller
                            control={control}
                            name="expert"
                            render={({
                              field: { onBlur, onChange, value },
                            }) => {
                              return (
                                <StyledExperts
                                  aria-invalid={
                                    !!errors.expert?.message
                                  }
                                  experts={
                                    data?.relationships
                                      .call_experts || []
                                  }
                                  onBlur={onBlur}
                                  onChange={(v) => {
                                    onChange(v);
                                    setValue("date", null);
                                    setValue("time", null);
                                    setValue("note", "");
                                    setSubmitted(false);
                                    setShowNote(false);

                                    setTimeout(() => {
                                      if (dateRef.current) {
                                        dateRef.current.scrollIntoView();
                                      }
                                    }, 100);
                                  }}
                                  selectedExpert={value}
                                  timeZone={timeZone}
                                />
                              );
                            }}
                          />
                        </ExpertFormField>
                      )}
                    </>
                  </TopBlock>
                  {selectedExpert && (
                    <div
                      style={{
                        display: showNote ? "none" : "initial",
                      }}
                    >
                      <SubTitle as="p" styleLevel="h2">
                        <Trans>Select day and time</Trans>
                      </SubTitle>
                      <TimeWarn>
                        <Trans>Times are in</Trans>{" "}
                        {
                          session.data?.user?.attributes
                            .timezone_human
                        }
                      </TimeWarn>
                      <DateFormField
                        error={errors.date?.message}
                        formGroup
                        label={t`Pick a date`}
                        labelTextClassName="label"
                        required
                      >
                        <Controller
                          control={control}
                          name="date"
                          render={({
                            field: { onBlur, onChange, value },
                          }) => {
                            return (
                              <StyledDates
                                key={selectedExpert}
                                ref={dateRef}
                                aria-invalid={!!errors.date?.message}
                                onBlur={onBlur}
                                onChange={(v) => {
                                  onChange(v);
                                  setValue("time", null);

                                  setTimeout(() => {
                                    if (timeRef.current) {
                                      timeRef.current.scrollIntoView();
                                    }
                                  }, 100);
                                }}
                                selectedDate={value}
                                selectedExpert={selectedExpert}
                                timeZone={timeZone}
                              />
                            );
                          }}
                        />
                      </DateFormField>
                      {selectedDate && (
                        <TimeFormField
                          error={errors.time?.message}
                          formGroup
                          label={t`Pick a time`}
                          labelTextClassName="label"
                          required
                        >
                          <Controller
                            control={control}
                            name="time"
                            render={({
                              field: { onBlur, onChange, value },
                            }) => {
                              return (
                                <StyledTimes
                                  key={selectedDate.getTime()}
                                  ref={timeRef}
                                  aria-invalid={
                                    !!errors.time?.message
                                  }
                                  onBlur={onBlur}
                                  onChange={(v) => {
                                    onChange(v);

                                    setTimeout(() => {
                                      if (firstContinueRef.current) {
                                        firstContinueRef.current.scrollIntoView();
                                      }
                                    }, 100);
                                  }}
                                  selectedDate={selectedDate}
                                  selectedExpert={selectedExpert}
                                  selectedTime={value}
                                  timeZone={timeZone}
                                />
                              );
                            }}
                          />
                        </TimeFormField>
                      )}
                    </div>
                  )}
                </>
              ) : null}
            </Skeleton>
            {!submitted &&
              selectedDate &&
              selectedTime &&
              selectedExpert && (
                <>
                  {!showNote && (
                    <>
                      <Disclaimer />
                      <div ref={firstContinueRef} />
                      <Button
                        kind="filledCoral"
                        onClick={() => {
                          setShowNote(true);
                        }}
                        resetWidth
                        type="button"
                      >
                        <Trans>Continue</Trans>
                      </Button>
                    </>
                  )}
                  {showNote && (
                    <>
                      <SelectedDate as="div" styleLevel="h2">
                        {formatDate(selectedTime, timeZone)}
                      </SelectedDate>
                      <NoteField
                        error={errors.note?.message}
                        label={t`Please describe what you'd like to discuss to help us connect you with the best experts`}
                        labelTextClassName="label"
                      >
                        <TextArea
                          css={`
                            max-width: 760px;
                            height: 160px;
                          `}
                          placeholder={t`Type your answer here`}
                          {...register("note")}
                        />
                      </NoteField>
                      {scheduleError && (
                        <Alert
                          css={`
                            margin-top: 10px;
                          `}
                          status="error"
                        >
                          {scheduleError instanceof
                            JSONApiErrorsException &&
                          scheduleError.errors[0]
                            ? scheduleError.errors[0].title
                            : renderError(scheduleError)}
                        </Alert>
                      )}
                      <ButtonsRow>
                        <Button
                          kind="outlinedCoral"
                          onClick={() => setShowNote(false)}
                          resetWidth
                          type="button"
                        >
                          <Trans>Change time</Trans>
                        </Button>
                        <Button
                          disabled={submitting}
                          kind="filledCoral"
                          resetWidth
                          type="submit"
                        >
                          {submitting && (
                            <SharedIcon
                              css="margin-inline-end: 5px"
                              type="spinner"
                            />
                          )}
                          <Trans>Continue</Trans>
                        </Button>
                      </ButtonsRow>
                    </>
                  )}
                </>
              )}
            {submitted && (
              <>
                <SubTitle as="p" styleLevel="h2">
                  <Trans>You&#39;re all set!</Trans>
                </SubTitle>
                <SubTitle
                  as="p"
                  css="margin-bottom: 10px"
                  styleLevel="h2"
                >
                  <Trans>
                    Scheduled for{" "}
                    {selectedTime &&
                      formatDate(selectedTime, timeZone)}
                  </Trans>
                </SubTitle>
                <Text textType="default">
                  <Trans>
                    We&#39;re sending you an email with the details.
                  </Trans>
                </Text>
              </>
            )}
          </Container>
        </Body>
      )}
    </PageLayout>
  );
};

export default CallSubjects;
