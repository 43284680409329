import { Trans } from "@lingui/macro";
import { FC, HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors, fonts } from "../../../../../constants";
import Title from "../../../../../lib/Title";
import mediaQuery from "../../../../../utils/mediaQuery";

const ProgramTitle = styled(Title)``;
const ProgramImage = styled.div`
  align-items: center;
  border-inline-end: 1px solid ${colors.brownLightGrey1};
  display: flex;
  display: -ms-flexbox;
  display: -webkit-box;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  justify-content: center;
  line-height: 0;
`;

const Description = styled.div`
  color: ${colors.brownGrey};
`;
const Content = styled.div`
  flex-grow: 1;
  font-size: 15px;
`;
const SeeMore = styled.div`
  align-items: flex-end;
  display: flex;
`;
const StyledSeeMore = styled(Link)`
  background: ${colors.green};
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ${fonts.main};
  font-size: 16px;
  line-height: 21px;
  padding: 15px 23px 14px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.075s;
  transition-property: color, border-color, background-color;
  transition-timing-function: ease-in-out;
  white-space: nowrap;

  &:hover {
    background-color: ${colors.greenHover};
  }
`;

const ProgramTitleLink = styled(Link)`
  text-decoration: none;
`;
const Container = styled.article`
  background-color: ${colors.white};
  border: 1px solid ${colors.brownLightGrey1};
  border-radius: 10px;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 5%);
  display: grid;
  grid-template-areas:
    "image content"
    "link link";
  margin-bottom: 20px;
  padding: 18px 20px 20px 20px;
  row-gap: 28px;

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQuery(
    "desktopMedium",
    css`
      grid-template-areas: "image content link";
      grid-template-columns: min-content 1fr min-content;
      padding-block: 30px;
      padding-inline-end: 20px;
      padding-inline-start: 40px;
    `,
  )}

  ${Content} {
    grid-area: content;
    padding-inline-end: 15px;
  }
  ${ProgramImage} {
    grid-area: image;
    margin-inline-end: 20px;
    padding-inline-end: 20px;
    width: 40px;

    ${mediaQuery(
      "desktopMedium",
      css`
        margin-inline-end: 30px;
        padding-inline-end: 40px;
        width: 120px;
      `,
    )}
  }
  ${SeeMore} {
    grid-area: link;
    min-width: 158px;
  }
  ${ProgramTitle} {
    margin-bottom: 3px;
  }

  ${StyledSeeMore} {
    width: 100%;
  }
`;
export interface ProgramProps extends HTMLAttributes<HTMLElement> {
  id: string;
  icon: ReactNode;
  url: string;
  title: string;
  description: string;
}
const Program: FC<ProgramProps> = ({
  id,
  icon,
  url,
  title,
  description,
  ...restProps
}) => {
  return (
    <Container {...restProps}>
      <ProgramImage>{icon}</ProgramImage>
      <Content>
        <ProgramTitleLink to={url}>
          <ProgramTitle level="h4">{title}</ProgramTitle>
        </ProgramTitleLink>
        <Description>{description}</Description>
      </Content>
      <SeeMore>
        <StyledSeeMore to={url}>
          <Trans>See more</Trans>
        </StyledSeeMore>
      </SeeMore>
    </Container>
  );
};

export default Program;
